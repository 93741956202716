import { WorkTable} from "./components/workTable";
import CustomSelect from "../../../../components/select/select";
import NewOrderModal from "./components/newOrderModal";
import {useDisclosure} from "@chakra-ui/hooks";
import Filter from "../../../../components/filter/Filter";
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const WorkRequest = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
        <Filter>
         <div className={'filter dashed-border pb-3 pt-1'}>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                <CustomSelect
                    label="W.O/N.O"
                    placeholder="W.O/N.O"
                    options={sampleOptions}
                />
                <CustomSelect
                    label="Project code"
                    placeholder="Project code"
                    options={sampleOptions}
                />
                <CustomSelect
                    label="Project name"
                    placeholder="Project name"
                    options={sampleOptions}
                />
                <CustomSelect
                    label="Project location"
                    placeholder="Project location"
                    options={sampleOptions}
                />
            </div>
        </div>
        </Filter>
        <div className={'flex justify-end pt-4'}>
            <button onClick={onOpen} className="btn bg-navy-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                <em className={'icon-plus'}></em>
                <span className={'ps-3'}>Add work order</span>
            </button>
        </div>
      <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          <WorkTable/>
      </div>
        <NewOrderModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </div>
  );
};


export default WorkRequest;
