import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React from "react";
import modalBg from './../../../../../assets/img/layout/modal-bottom.png';
import CustomSelect from "./../../../../../components/select/select";
import InputField from "../../../../../components/fields/InputField";
import CustomDatePicker from "../../../../../components/fields/DatePicker";

interface NewOrderModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const ScheduledMaintenanceModal: React.FC<NewOrderModalProps> = ({ isOpen, onOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#181C30] !opacity-80" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] lg:min-w-[680px] !max-w-[95%] ">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                    <ModalBody>
                        <div className="modal-body bg-white border-radius-40 px-[15px] md:px-[30px] pt-[35px] pb-[40px] max-w-[1080px] flex flex-col !z-[1004] dark:bg-navy-700"
                              style={{backgroundImage: `url(${modalBg})`}}>
                            <div className={'overflow-y-auto'}>
                                <h1 className="mb-[20px] fs-24 text-blue-800 dark:text-white font-bold">PPM Schedule for maintenance</h1>
                                <p className="mb-[20px] fs-20 font-regular dark:text-white">Please fill the following form</p>
                                <div className={'pb-3 pt-1'}>
                                    <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-4 px-4 pb-3">
                                        <div>
                                            <InputField
                                                label="S/N"
                                                placeholder="S/N"
                                                id="S/N"
                                                type="text"
                                            />
                                        </div>
                                        <div>
                                            <InputField
                                                label="Project code"
                                                placeholder="Project code"
                                                id="ProjectC ode"
                                                type="text"
                                            />
                                        </div>
                                        <div>
                                            <CustomSelect
                                                label="Project location"
                                                placeholder="Project location"
                                                options={sampleOptions}
                                            />
                                        </div>
                                        <div>
                                            <CustomDatePicker label="Contract start date" placeholder="Contract start date" />
                                        </div>
                                        <div>
                                            <CustomDatePicker label="Contract end date" placeholder="Contract end date" />
                                        </div>
                                        <div>
                                            <CustomSelect
                                                label="For the period"
                                                placeholder="Created by"
                                                options={sampleOptions}
                                            />
                                        </div>
                                        <div>
                                            <CustomSelect
                                                label="  "
                                                placeholder="To"
                                                options={sampleOptions}
                                            />
                                        </div>
                                        <div>
                                            <CustomSelect
                                                label="Frequency"
                                                placeholder="Frequency"
                                                options={sampleOptions}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-[20px] fs-15 font-semiBold text-blue-800 dark:text-white">Frequency</p>
                                <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-4 px-4 pb-3 custom-radius">
                                    <div className={'flex items-center dashed-border p-3'}>
                                        <div className={'flex items-center'}>
                                            <span className={'font-medium'} >Q1</span>
                                        </div>
                                        <div className={'flex ps-3 w-full'}>
                                            <div className={'w-10'}>
                                                <InputField
                                                    placeholder="1"
                                                    id=""
                                                    type="text"
                                                />
                                            </div>
                                            <div className={'ps-2 w-full'}>
                                                <CustomSelect
                                                    placeholder=""
                                                    options={sampleOptions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'flex items-center dashed-border p-3'}>
                                        <div className={'flex items-center'}>
                                            <span className={'font-medium'} >Q2</span>
                                        </div>
                                        <div className={'flex ps-3 w-full'}>
                                            <div className={'w-10'}>
                                                <InputField
                                                    placeholder="1"
                                                    id=""
                                                    type="text"
                                                />
                                            </div>
                                            <div className={'ps-2 w-full'}>
                                                <CustomSelect
                                                    placeholder=""
                                                    options={sampleOptions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'flex items-center dashed-border p-3'}>
                                        <div className={'flex items-center'}>
                                            <span className={'font-medium'} >Q3</span>
                                        </div>
                                        <div className={'flex ps-3 w-full'}>
                                            <div className={'w-10'}>
                                                <InputField
                                                    placeholder="1"
                                                    id=""
                                                    type="text"
                                                />
                                            </div>
                                            <div className={'ps-2 w-full'}>
                                                <CustomSelect
                                                    placeholder=""
                                                    options={sampleOptions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'flex items-center dashed-border p-3'}>
                                        <div className={'flex items-center'}>
                                            <span className={'font-medium'} >Q4</span>
                                        </div>
                                        <div className={'flex ps-3 w-full'}>
                                            <div className={'w-10'}>
                                                <InputField
                                                    placeholder="1"
                                                    id=""
                                                    type="text"
                                                />
                                            </div>
                                            <div className={'ps-2 w-full'}>
                                                <CustomSelect
                                                    placeholder=""
                                                    options={sampleOptions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button className="btn linear text-white bg-blue-800 px-5 py-3 text-base font-medium transition duration-200 hover:bg-blue-900 active:bg-blue-900 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
    ); };
export default ScheduledMaintenanceModal;