import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import {Link} from "react-router-dom";

export default function SignIn() {
  return (
    <div className="flex w-full items-center justify-center px-2 lg:items-center lg:justify-end">
      {/* Sign in section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:px-32 xl:max-w-[750px] bg-white px-10 pt-14 pb-10 lg-radius">
        <h4 className="mb-2.5 font-bold text-navy-400 dark:text-white text-center fs-27">
          Welcome Back to ALFOADIA!
        </h4>
        <p className="mb-9 ml-1 text-base text-center font-regular fs-19">
          Please enter your login details.
        </p>

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email Address"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password"
          placeholder="Password"
          id="password"
          type="password"
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-navy-500 hover:text-navy-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        <div>
          <div className={'flex justify-center pt-4'}>
            <Link to="/private">
              <button className="btn linear mt-2 bg-navy-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                login
              </button>
            </Link>
          </div>
          <div className={'flex justify-center py-4'}>
            <span className=" text-sm font-medium dark:text-gray-600">
              or
            </span>
          </div>
          <div className={'flex justify-center'}>
            <Link to="/auth/sign-up">
              <button className="btn border-2 border-navy-500 px-5 py-3 text-base font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10">
                Sing up
              </button>
            </Link>
          </div>



        </div>

      </div>
    </div>
  );
}
