import IncrementDecrement from "../../../../../components/IncrementDecrement";
import React from "react";
import isMobile from "../../../../../utils/mobileUtils";


const TABLE_HEAD = ["S/N", "Description of Material / Item / Spare parts", "QTY", "Unit Cost", "Total Cost", "Attachment", "Actions" ];

const TABLE_ROWS = [
    {
        SN: "1",
        description: "Tube Light",
        unit_cost: "50",
        total_cost: "150",
    },
    {
        SN: "1",
        description: "Tube Light",
        unit_cost: "50",
        total_cost: "150",
    },
    {
        SN: "1",
        description: "Tube Light",
        unit_cost: "50",
        total_cost: "150",
    },

];

export function WorkRequestTable() {
    const isMobileView = isMobile();
    return (
        <div className="h-full w-full">

            <div className="px-0 overflow-x-auto">



                { !isMobileView ?
                    (   <table className="work-table w-full min-w-max table-auto text-left custom-table">
                        <thead className={' border-radius-20'}>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2"
                                >
                                <span className="font-normal text-white" >
                                    {head}
                                </span>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {TABLE_ROWS.map(
                            (
                                {
                                    SN,
                                    description,
                                    unit_cost,
                                    total_cost,
                                },
                                index,
                            ) => {
                                const isLast = index === TABLE_ROWS.length - 1;

                                const classes = isLast
                                    ? 'p-2'
                                    : "p-2 border-b border-blue-gray-50";
                                return (
                                    <tr key={SN}>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <p className="font-medium text-gray-700 fs-16 ps-4">
                                                    {SN}
                                                </p>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <p className="font-medium text-gray-700 fs-16 ps-4">
                                                    {description}
                                                </p>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <IncrementDecrement minValue={0} maxValue={10} />
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {unit_cost}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {total_cost}
                                            </p>
                                        </td>

                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-upload fs-14 text-blue-300'} />
                                                </button>
                                            </div>
                                        </td>


                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button className="action-btn bg-light hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-pen fs-16 text-white'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                        </tbody>
                    </table>)
                    : (
                        <div className={'mobileView'}>
                            {TABLE_ROWS.map((body, rowIndex) => (
                                <div key={rowIndex} className="card-table my-3">
                                    <div className="">

                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                S/N
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                41
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Description of Material / Item / Spare parts
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                1545
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                QTY
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Project Name
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Unit Cost
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Project Location
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Total Cost
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                41
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Attachment
                                            </p>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-upload fs-14 text-blue-300'} />
                                                </button>
                                            </div>
                                        </div>
                                        <div className={'flex justify-center pt-4'}>
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn bg-light hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-pen fs-16 text-white'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                }
                <div className={'flex justify-center md:justify-end md:pe-40'}>
                <div className={'flex  p-3 dashed-border-black md:me-[120px]'}>
                    <span className={'font-bold fs-16 px-4'}>Total</span>
                    <span className={'font-medium fs-16 px-4'}>100</span>
                </div>
                </div>
            </div>
        </div>
    );
}