

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React from "react";
import modalBg from '../../../../../assets/img/layout/modal-bottom.png';
import InputField from "../../../../../components/fields/InputField";
import Upload from "../../../profile/components/Upload";
import CustomTimePicker from "../../../../../components/fields/TimePicker";
import CustomSelect from "../../../../../components/select/select";
import CustomDatePicker from "../../../../../components/fields/DatePicker";

interface NewOrderModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const PurchaseModal: React.FC<NewOrderModalProps> = ({ isOpen, onOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#181C30] !opacity-80" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] lg:min-w-[680px] !max-w-[95%]">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                <ModalBody>
                    <div className="modal-body bg-white border-radius-40 px-[15px] md:px-[30px] pt-[35px] pb-[40px] flex flex-col !z-[1004]"
                         style={{backgroundImage: `url(${modalBg})`}}>
                        <h1 className="mb-2 fs-24 text-blue-800 font-bold">Add / Edit item</h1>
                        <p className="mb-[20px] fs-20 mb-0 pb-2  font-regular">Please fill the following form</p>
                        <div className={'pb-3 pt-1 overflow-auto'}>
                            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-4 px-4 pb-3">
                                <div>
                                    <InputField
                                        label="S/N"
                                        placeholder="3"
                                        id="Code"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-4 px-4 pb-3">
                                <div>
                                    <InputField
                                        label="Code"
                                        placeholder="Code"
                                        id="Code"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label="Name"
                                        placeholder="Name"
                                        id="Name"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <CustomSelect
                                        label="Location"
                                        placeholder="Location"
                                        options={sampleOptions}
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label="Contact person name"
                                        placeholder="Contact person name"
                                        id="Contact"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label="Telephone"
                                        placeholder="Telephone"
                                        id="Telephone"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <InputField
                                        label="Email"
                                        placeholder="Email"
                                        id="Email"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <CustomSelect
                                        label="Project name"
                                        placeholder="Project name"
                                        options={sampleOptions}
                                    />
                                </div>
                                <div>
                                    <CustomDatePicker label="Contract start date" placeholder="Contract start date" />
                                </div>
                                <div>
                                    <CustomDatePicker label="Contract end date" placeholder="Contract end date" />
                                </div>
                            </div>

                            <div className={'dashed-border pb-3 pt-1'}>
                                <div className={'flex justify-end pe-4 pt-4'}>
                                    <button className="simple-addBtn bg-grayBlue hover:bg-navy-600 active:bg-navy-600 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200   font-medium text-white transition duration-200 dark:text-white">
                                        <em className={'icon-plus text-white'}></em>
                                    </button>
                                </div>
                                <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                                    <div>
                                        <CustomSelect
                                            label="Created by"
                                            placeholder="Created by"
                                            options={sampleOptions}
                                        />
                                    </div>
                                    <div>
                                        <CustomTimePicker label={'From'}></CustomTimePicker>
                                    </div>
                                    <div>
                                        <CustomTimePicker label={'To'}></CustomTimePicker>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <h4 className={'font-semibold fs-15 text-blue-800 pt-3'}>Upload Files</h4>
                                <div className="flex items-center">
                                    <Upload/>
                                    <div className={'ps-4'}>
                                        <button className="simple-addBtn bg-grayBlue hover:bg-navy-600 active:bg-navy-600 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200   font-medium text-white transition duration-200 dark:text-white">
                                            <em className={'icon-plus text-white'}></em>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button className="btn linear text-white bg-blue-800 px-5 py-3 text-base font-medium transition duration-200 hover:bg-blue-900 active:bg-blue-900 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                                Submit
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    ); };
export default PurchaseModal;