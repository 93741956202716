
import { Link, Routes, Route, Navigate } from "react-router-dom";

import home_bg from "../../../assets/img/layout/home-bg.png";
import logo from "../../../assets/img/layout/logo.png";
import vision from "../../../assets/img/layout/vision.png";
import React from "react";

export default function Public() {
    // document.documentElement.dir = "ltr";
    return (
        <div>
            <div className="relative  min-h-screen w-full !bg-white dark:!bg-navy-900 home-page"
                 style={{ backgroundImage: `url(${home_bg})` , backgroundSize:'cover' , backgroundRepeat:'no-repeat'}}>
                {/*<FixedPlugin />*/}
                <main>
                    <div className="min-h-full w-full pt-5 md:pt-12  lg:pt-0 px-10 md:px-15 lg:px-20 pb-5">
                        <div className="flex flex-wrap pt-3 md:pt-8">
                            <img className= 'me-8 mb-3 logo' src={logo} alt="logo" />
                            <img className="vision" src={vision} alt="vision-2030" />
                        </div>
                        <h1 className={'font-bold fs-25 md:fs-40 pt-5 md:pt-14 text-white'}>Welcome to alfoadia company.</h1>
                        <div className={'pt-5 md:pt-12 md:max-w-[70%] lh-35'}>
                            <p className={'text-white fs-17 font-regular '}>Ahmed Fouad AlJizawi founded Al Foadia in 1977 based on the core values of honesty, quality, teamwork, integrity and commitment. Today, the company’s reach is nation-wide and our people uphold its founder’s vision to provide valuable services to clients, build partnerships and deliver unmatched quality of service.</p>
                            <p className={'text-white fs-17 font-regular'}>Dedication to quality of service, hard work and responsiveness to clients’ needs are the company’s credo and behind our success in such a competitive market. Our ability to address changing conditions and demands, attract and serve diverse sectors and expand our services resulted in a growth far beyond our founder’ dreams.</p>
                        </div>
                        <div className={'flex pt-3'}>
                            <div className={'w-50'}>
                                <Link to="/auth" className="mt-0 w-max lg:pt-10">
                                    <button className="w-full home-btn  me-5 flex-col md:flex-row flex items-center justify-center px-3 md:px-5 py-4 text-navy-500 transition duration-200 hover:bg-navy-200 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                        <em className={'icon-company fs-50 md:fs-80 mb-3 md:mb-0'}></em>
                                        <span className={'font-semiBold fs-20  ps-5'}>Company</span>
                                    </button>
                                </Link>
                            </div>
                            <div className={'w-50'}>
                                <Link to="/auth" className="mt-0 w-max lg:pt-10">
                                    <button className="w-full home-btn ms-3 h-full flex-col md:flex-row flex items-center justify-center text-navy-500 px-3 md:px-5 py-4  transition duration-200 hover:bg-navy-200 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                        <em className={'icon-individual mb-3 md:mb-0'}></em>
                                        <span className={'font-semiBold ps-5'}>Individual</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
