import React, {FC} from "react";
import isMobile from "../../../../utils/mobileUtils";


const TABLE_HEAD = ["Code", "Name", "Location", "Contact Person Name", "Telephone", "Working hours", "Number of WO","Created on","Created by","Attachment", "Actions" ];
type ProjectData = {
    Code: string,
    Name: string,
    Location: string,
    Contact_Person_Name: string,
    Telephone:string,
    Working_hours: string,
    Number_of_WO: string,
    Created_on: string,
    Created_by: string,
    Attachment: string,
};
const TABLE_ROWS: ProjectData[]  = [
    {
        Code: "1",
        Name: "CCDR",
        Location: "Karsd",
        Contact_Person_Name: "Riyadh",
        Telephone: "Riyadh",
        Working_hours: "Riyadh",
        Number_of_WO: "Riyadh",
        Created_on: "Riyadh",
        Created_by: "Riyadh",
        Attachment: "Riyadh",
    },
    {
        Code: "1",
        Name: "CCDR",
        Location: "Karsd",
        Contact_Person_Name: "Riyadh",
        Telephone: "Riyadh",
        Working_hours: "Riyadh",
        Number_of_WO: "Riyadh",
        Created_on: "Riyadh",
        Created_by: "Riyadh",
        Attachment: "Riyadh",
    },
    {
        Code: "1",
        Name: "CCDR",
        Location: "Karsd",
        Contact_Person_Name: "Riyadh",
        Telephone: "Riyadh",
        Working_hours: "Riyadh",
        Number_of_WO: "Riyadh",
        Created_on: "Riyadh",
        Created_by: "Riyadh",
        Attachment: "Riyadh",
    },
    {
        Code: "1",
        Name: "CCDR",
        Location: "Karsd",
        Contact_Person_Name: "Riyadh",
        Telephone: "Riyadh",
        Working_hours: "Riyadh",
        Number_of_WO: "Riyadh",
        Created_on: "Riyadh",
        Created_by: "Riyadh",
        Attachment: "Riyadh",
    },
    {
        Code: "1",
        Name: "CCDR",
        Location: "Karsd",
        Contact_Person_Name: "Riyadh",
        Telephone: "Riyadh",
        Working_hours: "Riyadh",
        Number_of_WO: "Riyadh",
        Created_on: "Riyadh",
        Created_by: "Riyadh",
        Attachment: "Riyadh",
    },

];

interface ProjectTableProps {
    openModal: () => void;
}
const ProjectTable: FC<ProjectTableProps> = ({ openModal }) => {
    const isMobileView = isMobile();
    return (
        <div className="h-full w-full">


            { !isMobileView ?
                (  <div className="px-0 overflow-x-auto">
                    <table className="w-full min-w-max table-auto text-left custom-table">
                        <thead className={' border-radius-20'}>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2"
                                >
                                <span className="font-normal text-white" >
                                    {head}
                                </span>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {TABLE_ROWS.map(
                            (
                                {
                                    Code,
                                    Name,
                                    Location,
                                    Contact_Person_Name,
                                    Telephone,
                                    Working_hours,
                                    Number_of_WO,
                                    Created_on,
                                    Created_by,
                                    Attachment,

                                },
                                index,
                            ) => {
                                const isLast = index === TABLE_ROWS.length - 1;

                                const classes = isLast
                                    ? 'p-2'
                                    : "p-2 border-b border-blue-gray-50";
                                return (
                                    <tr key={Code}>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <p className="font-medium text-gray-700 fs-16 ps-4">
                                                    {Code}
                                                </p>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Name}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Location}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Contact_Person_Name}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Telephone}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Working_hours}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Number_of_WO}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Created_on}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Created_by}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Attachment}
                                            </p>
                                        </td>

                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button onClick={openModal} className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button onClick={openModal} className="action-btn bg-light hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-pen fs-16 text-white'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                        </tbody>
                    </table>
                </div>)
                : (
                    <div className={'mobileView'}>
                        {TABLE_ROWS.map((body, rowIndex) => (
                            <div key={rowIndex} className="card-table my-3">
                                <div className="">
                                    {TABLE_HEAD.slice(0, -1).map((head, headIndex) => (
                                        <div className={'flex justify-between px-4'} key={headIndex}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {head}
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {body[head.replace(/ /g, '_') as keyof ProjectData]}
                                            </p>
                                        </div>
                                    ))}
                                    <div className={'flex justify-center pt-4'}>
                                        <button onClick={openModal} className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                            <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                        </button>
                                        <button onClick={openModal} className="action-btn bg-light hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                            <em className={'icon-pen fs-16 text-white'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    );
};

export default ProjectTable;