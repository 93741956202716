import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";

import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";

import avatar from "assets/img/avatars/avatar4.png";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  open: boolean;
}) => {
  const { onOpenSidenav, brandText, open } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const customBoxShadow ='10px 7px 16px 0px rgba(65, 163, 244, 0.06)'

  return (
      <nav className="sticky top-0 z-20 flex flex-col-reverse md:flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
        <div className="lg:ml-[6px]">
          <button className={`hidden md:flex absolute left-[-15px] sidebarControlBtn border-radius-50 bg-white shadow-xl shadow-shadow-500 hover:bg-navy-200 active:bg-navy-700 dark:bg-navy-400 dark:hover:bg-navy-300 
              dark:active:bg-navy-200 flex items-center justify-center rounded-full p-2 text-navy-500 transition duration-200 hover:cursor-pointer dark:text-white`} onClick={onOpenSidenav}>
            <em className={`${ open ? 'icon-chevron-right' : 'icon-chevron-left' }`}></em>
          </button>
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
            <Link
                to="#"
                className="font-regular fs-20 capitalize hover:text-navy-700 dark:hover:text-white ps-4"
            >
              {brandText}
            </Link>
          </p>
        </div>

        <div className="w-full md:w-auto relative mt-[3px] flex h-[61px] flex-grow flex-wrap-reverse items-center justify-around gap-2 rounded-full bg-white px-2 py-2   dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1  xl:gap-2">
           <span className="cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
                         onClick={onOpenSidenav}>
              <em className="icon-burger fs-20" />
           </span>
          <div className="hidden border border-navy-200 md:flex h-full items-center box-shadow rounded-full bg-white text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]" style={{boxShadow:customBoxShadow}}>
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
                type="text"
                placeholder="Search for anything..."
                className="block h-full w-full rounded-full text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
            />
          </div>

          {/* start Notification */}
          <Dropdown
              button={
                <span className={'cursor-pointer bg-white dark:bg-navy-200 box-shadow rounded-3xl flex items-center p-2 justify-center relative'}>
                  <div className={`absolute flex items-center justify-center top-[-10px] right-0 border-radius-50 bg-red-500 w-[18px] h-[18px] text-xs font-bold uppercase text-white transition duration-200 dark:bg-red-400`}>
                    10
                  </div>
                   <em className={'icon-notification fs-20 text-navy-600 dark:text-white'}></em>
                </span>
              }
              animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
              children={
                <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                  <div className="flex items-center justify-between">
                    <p className="text-base font-bold text-navy-700 dark:text-white">
                      Notification
                    </p>
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      Mark all read
                    </p>
                  </div>

                  <button className="flex w-full items-center">
                    <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-navy-500 py-4 text-2xl text-white">
                      <BsArrowBarUp />
                    </div>
                    <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                      <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                        New Update: Horizon UI Dashboard PRO
                      </p>
                      <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                        A new update for your downloaded item is available!
                      </p>
                    </div>
                  </button>

                  <button className="flex w-full items-center">
                    <div className="flex h-full w-[85px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-navy-500 py-4 text-2xl text-white">
                      <BsArrowBarUp />
                    </div>
                    <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                      <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                        New Update: Horizon UI Dashboard PRO
                      </p>
                      <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                        A new update for your downloaded item is available!
                      </p>
                    </div>
                  </button>
                </div>
              }
              classNames={"py-2 top-8 md:top-4 -left-[45px] md:-left-[440px] w-max"}
          />

          <div
              className="cursor-pointer text-gray-600"
              onClick={() => {
                if (darkmode) {
                  document.body.classList.remove("dark");
                  setDarkmode(false);
                } else {
                  document.body.classList.add("dark");
                  setDarkmode(true);
                }
              }}
          >
            {darkmode ? (
                <span className={'cursor-pointer bg-white dark:bg-navy-200 box-shadow rounded-3xl flex items-center p-2 justify-center relative'}>
                   <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
                  </span>

            ) : (
                <span className={'cursor-pointer bg-white box-shadow rounded-3xl flex items-center p-2 justify-center relative'}>
                <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
              </span>

            )}
          </div>
          {/* Profile & Dropdown */}
          <div className={'flex items-center bg-white dark:bg-grayBlue  dark:text-white  border border-navy-200 border-radius-50 box-shadow px-2 py-1'}>
            <div className={'flex items-center'}>
              <img
                  className="h-10 w-10 rounded-full"
                  src={avatar}
                  alt="avatar"
              />
              <div className={'px-3'}>
                <p className={'font-regular fs-14 dark:text-white'}>Alex meian</p>
                <p className={'font-regular fs-12 text-gray-600 dark:text-white'}>Prodcut manager</p>
              </div>
            </div>
            <Dropdown
                button={
                  <em className={'icon-chevron-down fs-12 px-2 cursor-pointer'}></em>
                }
                children={
                  <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white  bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700  dark:text-white dark:shadow-none">
                    <div className="p-4">
                      <div className="flex items-center gap-2">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          👋 Hey, Alex
                        </p>
                      </div>
                    </div>
                    <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                    <div className="flex flex-col p-4">
                      <a
                          href=" "
                          className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                      >
                        Profile Settings
                      </a>
                      <a
                          href=" "
                          className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                      >
                        Newsletter Settings
                      </a>
                      <a
                          href=" "
                          className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                      >
                        Log Out
                      </a>
                    </div>
                  </div>
                }
                classNames={"py-2 top-8 -left-[180px] w-max"}
            />
          </div>
        </div>
      </nav>
  );
};

export default Navbar;
