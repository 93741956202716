import React from 'react';


interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    return (
        <div className="flex items-center justify-between  bg-white px-4 py-3 sm:px-6">

            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
                <div>
                    <nav className="isolate inline-flex -space-x-px" aria-label="Pagination">
                        <a
                            href="#"
                            className="relative inline-flex items-center border-radius-50 px-2 py-2 text-gray-400 mx-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            <em className={'icon-arrow-left'}></em>
                        </a>
                        {generatePageNumbers().map((page) => (
                            <a
                                key={page}
                                href="#"
                                className={`relative inline-flex items-center px-4 py-2 mx-1 text-sm font-semiBold border-radius-50 ${
                                    page === currentPage
                                        ? 'bg-navy-100 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                        : 'text-gray-900  hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                                }`}
                                onClick={() => onPageChange(page)}
                            >
                                {page}
                            </a>
                        ))}
                        <a
                            href="#"
                            className="relative inline-flex items-center border-radius-50 px-2 py-2 text-gray-400 mx-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            <em className={'icon-arrow-right'}></em>
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
