
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes";
import home_bg from "../../assets/img/layout/home-bg.png";
import logo from "../../assets/img/layout/logo.png";
import vision from "../../assets/img/layout/vision.png";
import React from "react";

export default function Auth() {
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
      <div>
        <div className="relative  min-h-screen w-full !bg-white dark:!bg-navy-900 home-page"
             style={{ backgroundImage: `url(${home_bg})` , backgroundSize:'cover' , backgroundRepeat:'no-repeat'}}>
            <main className={`mx-auto min-h-screen`}>
              <div className="relative">
                <div className="mx-auto min-h-full w-full   pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 ">
                  <div className="flex justify-center md:justify-start flex-wrap pt-3 md:pt-6 mb-md-0">
                    <img className= 'me-8 mb-3 logo' src={logo} alt="logo" />
                    <img className="vision" src={vision} alt="vision-2030" />
                  </div>
                  <Routes>
                    {getRoutes(routes)}
                    <Route
                        path="/"
                        element={<Navigate to="/auth/sign-in" replace />}
                    />
                  </Routes>
                </div>

              </div>
            </main>
        </div>
      </div>
  );
}
