

import Switch from "../../../components/switch";
import CheckCard from "../../../components/card/CheckCard";
import Checkbox from "components/checkbox";
import CustomSelect from "../../../components/select/select";
import React, {useState} from "react";
import IncrementDecrement from "../../../components/IncrementDecrement";
import Filter from "../../../components/filter/Filter";

const sampleOptions = [
    {label: "option 1", value: "option-1"},
    {label: "option 2", value: "option-2"},
    {label: "option 3", value: "option-3"},
];

const CustomerSatisfaction = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 3;

    const handleNext = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };
    return (
    <div>
        <Filter>
            <div className={'filter dashed-border pb-3 pt-1'}>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-6 pb-3">
               <div className={'col-span-1 border-r mt-2'}>
                   <div className={'grid grid-cols-2 align-middle pt-2'}>
                       <p className={'font-medium fs-15'}>Name</p>
                       <p className={'font-medium fs-15'}>Henry</p>
                   </div>
               </div>
                <div className={'col-span-1 pt-2 ps-4 mt-2'}>
                    <div className={'grid grid-cols-2'}>
                        <p className={'font-medium fs-15'}>Position</p>
                        <p className={'font-medium fs-15'}>BAP</p>
                    </div>
                </div>
                <div className={'col-span-2'}>
                    <div className={'flex justify-end'}>
                        <button className="btn bg-light py-[12px] ms-2 font-medium text-white transition duration-200 hover:bg-grayBlue active:bg-grayBlue dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                            Download
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </Filter>
        <div className={'pb-3 pt-1'}>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 pb-3">
                <div>
                     <CustomSelect
                         label="Project name"
                         placeholder="Project name"
                         options={sampleOptions}
                     />
                 </div>
                <div>
                     <CustomSelect
                         label="Project location"
                         placeholder="Project location"
                         options={sampleOptions}
                     />
                 </div>
            </div>
        </div>
        {currentStep === 2 && (
            <h2 className={'text-blue-800 font-bold fs-20 ps-2 pb-4'}>How do you find our project assigned Staff?</h2>
        )}
        {currentStep === 1 && (
            <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-3">
                <div>
                    <CheckCard
                        headerContent={
                            <p className={'font-medium fs-15'}>How do you consider our Hospitality Services?</p>
                        }
                        bodyContent={
                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                <div>
                                    <Checkbox color="navy" label={'Excellent'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'Needs improvment'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'Good'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'N/A'}  />
                                </div>
                                <div className={'px-3 col-span-2 pt-3'}>
                                    <label className={'font-medium fs-15 ps-3 mb-2'}>Any other comment’s</label>
                                    <textarea placeholder="Any other comment’s"
                                              className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">
                                    </textarea>
                                </div>
                            </div>
                        }
                        theme="indigo-100 indigo"
                    />
                </div>
                <div>
                    <CheckCard
                        headerContent={
                            <p className={'font-medium fs-15'}>How do you find housekeeping Services?</p>
                        }
                        bodyContent={
                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                <div>
                                    <Checkbox color="navy" label={'Excellent'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'Needs improvment'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'Good'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'N/A'}  />
                                </div>
                                <div className={'px-3 col-span-2 pt-3'}>
                                    <label className={'font-medium fs-15 ps-3 mb-2'}>Any other comment’s</label>
                                    <textarea placeholder="Any other comment’s"
                                              className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">
                                    </textarea>
                                </div>
                            </div>
                        }
                        theme="cyan-200 cyan"
                    />
                </div>
                <div>
                    <CheckCard
                        headerContent={
                            <p className={'font-medium fs-15'}>How do you consider our Hospitality Services?</p>
                        }
                        bodyContent={
                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                <div>
                                    <Checkbox color="navy" label={'Excellent'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'Needs improvment'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'Good'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'N/A'}  />
                                </div>
                                <div className={'px-3 col-span-2 pt-3'}>
                                    <label className={'font-medium fs-15 ps-3 mb-2'}>Any other comment’s</label>
                                    <textarea placeholder="Any other comment’s"
                                              className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">
                                    </textarea>
                                </div>
                            </div>
                        }
                        theme="pink-200 pink"
                    />
                </div>
            </div>
        )}
        {currentStep === 2 && (
            <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-3">
                <div>
                    <CheckCard
                        headerContent={
                            <p className={'font-medium fs-15'}>Hygienic</p>
                        }
                        bodyContent={
                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                <div>
                                    <Checkbox color="navy" label={'Yes'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'No'}  />
                                </div>
                                <div className={'px-3 col-span-2 pt-3'}>
                                    <label className={'font-medium fs-15 ps-3 mb-2'}>Any other comment’s</label>
                                    <textarea placeholder="Any other comment’s"
                                              className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">
                                    </textarea>
                                </div>
                            </div>
                        }
                        theme="indigo-100 indigo"
                    />
                </div>
                <div>
                    <CheckCard
                        headerContent={
                            <p className={'font-medium fs-15'}>Wearing complete uniform</p>
                        }
                        bodyContent={
                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                <div>
                                    <Checkbox color="navy" label={'Yes'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'No'}  />
                                </div>
                                <div className={'px-3 col-span-2 pt-3'}>
                                    <label className={'font-medium fs-15 ps-3 mb-2'}>Any other comment’s</label>
                                    <textarea placeholder="Any other comment’s"
                                              className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">
                                    </textarea>
                                </div>
                            </div>
                        }
                        theme="cyan-200 cyan"
                    />
                </div>
                <div>
                    <CheckCard
                        headerContent={
                            <p className={'font-medium fs-15'}>Wearing ID cards whilst on duty</p>
                        }
                        bodyContent={
                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                <div>
                                    <Checkbox color="navy" label={'Yes'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'No'}  />
                                </div>
                                <div className={'px-3 col-span-2 pt-3'}>
                                    <label className={'font-medium fs-15 ps-3 mb-2'}>Any other comment’s</label>
                                    <textarea placeholder="Any other comment’s"
                                              className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">
                                    </textarea>
                                </div>
                            </div>
                        }
                        theme="pink-200 pink"
                    />
                </div>
            </div>
        )}
        {currentStep === 3 && (
            <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-3">
                <div>
                    <CheckCard
                        headerContent={
                            <p className={'font-medium fs-15'}>Would you likely to recommend ‘AL FOADIA’ to others?</p>
                        }
                        bodyContent={
                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                <div>
                                    <Checkbox color="navy" label={'Extremely likely'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'Not sure'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'Likely'}  />
                                </div>
                                <div>
                                    <Checkbox color="navy" label={'Unlikely'}  />
                                </div>
                                <div className={'px-3 col-span-2 pt-3'}>
                                    <label className={'font-medium fs-15 ps-3 mb-2'}>Any other comment’s</label>
                                    <textarea placeholder="Any other comment’s"
                                              className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">
                                        </textarea>
                                </div>
                            </div>
                        }
                        theme="indigo-100 indigo"
                    />
                </div>
                <div>
                    <CheckCard
                        headerContent={
                            <p className={'font-medium fs-15'}>On the scale of 1 to 10, how would you rate the overall services being provided to you by ‘AL FOADIA’?</p>
                        }
                        bodyContent={
                            <div className="h-full">
                                <div className={'flex justify-center w-full'}>
                                    <IncrementDecrement minValue={0} maxValue={10} />
                                </div>
                                <div className={'px-3 col-span-2 pt-3'}>
                                    <label className={'font-medium fs-15 ps-3 mb-2'}>Any other comment’s</label>
                                    <textarea placeholder="Any other comment’s"
                                              className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">
                                        </textarea>
                                </div>
                            </div>
                        }
                        theme="cyan-200 cyan"
                    />
                </div>
                <div>
                    <CheckCard
                        headerContent={
                            <p className={'font-medium fs-15'}>What should ‘ AL FOADIA’ do to make you a  mroe satisfied customer?</p>
                        }
                        bodyContent={
                            <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                                <div className={'px-3 col-span-2 pt-3'}>
                                    <label className={'font-medium fs-15 ps-3 mb-2'}>Any comment’s</label>
                                    <textarea placeholder="Any other comment’s"
                                              className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">
                                        </textarea>
                                </div>
                            </div>
                        }
                        theme="pink-200 pink"
                    />
                </div>
            </div>
        )}

        <div className={'flex justify-center pt-10'}>
            <button
                className="btn border border-navy-500 border-navy-500 px-5 py-[12px] me-2 font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10"
                onClick={handlePrevious}
                disabled={currentStep === 1}
            >
                Previous
            </button>
            <button
                className="btn linear bg-navy-500 py-[12px] ms-2 font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200"
                onClick={handleNext}
                disabled={currentStep === totalSteps}
            >
                Next
            </button>
        </div>
    </div>
  );
};


export default CustomerSatisfaction;
