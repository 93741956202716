import React from "react";
import {
    StaffChartData, StaffChartOption,
} from "./charts-data";
import BarChart from "../../../../../components/charts/BarChart";

const StaffChart = () => {
  return (
      <div className="h-[420px]">
          <BarChart
              chartData={StaffChartData}
              chartOptions={StaffChartOption}
          />
      </div>
  );
};

export default StaffChart;
