import React from 'react';

interface StepperProps {
    steps?: React.ReactNode[];
    stepsNbr?: number;
    activeStep: number;
    completedSteps: number[];
    stepLabels?: string[];
    handleNext: () => void;
    handlePrevious: () => void;
}

const Stepper: React.FC<StepperProps> = ({  activeStep,stepsNbr, completedSteps }) => {
    const steps = Array.from({ length: stepsNbr }, (_, index) => index);
    return (
        <div className={`custom-stepper pt-5 md:pt-0 ${ stepsNbr === 3 ? 'three-step' : '' }`}>
            <div className="flex space-x-4 justify-around md:justify-center">
                {steps.map((step, index) => (
                        <div className={`stepper ${
                            completedSteps.includes(index) || activeStep === index ? 'active' : ''
                        }`}>
                            <div
                                key={index}
                                className={`step flex items-center justify-center ${
                                    completedSteps.includes(index) || activeStep === index ? 'activeStep' : 'not-active'
                                }`}
                            >
                                {completedSteps.includes(index) && activeStep !== index ? (
                                    <em className={'icon-check fs-15'}></em>
                                ) : (
                                    <span> {index +1}</span>
                                )}
                            </div>
                        </div>
                ))}
            </div>
        </div>
    );
};

export default Stepper;
