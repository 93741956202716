import React from "react";
import {useNavigate} from 'react-router-dom';
import {useDisclosure} from "@chakra-ui/hooks";
import ActivateEmployeeModal from "./activateEmployeeModal";
import isMobile from "../../../../../utils/mobileUtils";

const TABLE_HEAD = ["Emp No", "Full Name", "Role", "Department",
    "Phone Number", "Email", "Nationality", "Supervisor Name","Account Status","Actions" ];
type EmployeeData = {
    EmpNo: string;
    FullName: string;
    Role: string;
    Department: string;
    PhoneNumber: string;
    Email: string;
    Nationality: string;
    SupervisorName: string;
    AccountStatus: string;
};
const TABLE_ROWS: EmployeeData[] = [
    {
        EmpNo: "19",
        FullName: "CCDR fff",
        Role: "Kar sd",
        Department: "Riyadh",
        PhoneNumber: "Riyadh",
        Email: "Riyadh",
        Nationality: "Riyadh",
        SupervisorName: "Riyadh",
        AccountStatus: "Riyadh",
    },
    {
        EmpNo: "11",
        FullName: "CCDR",
        Role: "Karsd",
        Department: "Riyadh",
        PhoneNumber: "Riyadh",
        Email: "Riyadh",
        Nationality: "Riyadh",
        SupervisorName: "Riyadh",
        AccountStatus: "Riyadh",
    },
    {
        EmpNo: "1",
        FullName: "CCDR",
        Role: "Karsd",
        Department: "Riyadh",
        PhoneNumber: "Riyadh",
        Email: "Riyadh",
        Nationality: "Riyadh",
        SupervisorName: "Riyadh",
        AccountStatus: "Riyadh",
    },
    {
        EmpNo: "07",
        FullName: "CCDR",
        Role: "Karsd",
        Department: "Riyadh",
        PhoneNumber: "Riyadh",
        Email: "Riyadh",
        Nationality: "Riyadh",
        SupervisorName: "Riyadh",
        AccountStatus: "Riyadh",
    },
];

export function EmployeesTable() {
    const navigate = useNavigate();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const employeeDetails = () => {
        navigate('/private/employees/employee-details');
    };

    const isMobileView = isMobile();
    return (
        <div className="h-full w-full">
            { !isMobileView ?
                (<div className="px-0 overflow-x-auto">
                    <table className="w-full min-w-max table-auto text-left custom-table">
                        <thead className={' border-radius-20'}>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2"
                                >
                                <span className="font-normal text-white" >
                                    {head}
                                </span>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {TABLE_ROWS.map(
                            (
                                {
                                    EmpNo,
                                    FullName,
                                    Role,
                                    Department,
                                    PhoneNumber,
                                    Email,
                                    Nationality,
                                    SupervisorName,
                                    AccountStatus

                                },
                                index,
                            ) => {
                                const isLast = index === TABLE_ROWS.length - 1;

                                const classes = isLast
                                    ? 'p-2'
                                    : "p-2 border-b border-blue-gray-50";
                                return (
                                    <tr key={EmpNo}>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <p className="font-medium text-gray-700 fs-16 ps-4">
                                                    {EmpNo}
                                                </p>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {FullName}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Role}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Department}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {PhoneNumber}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Email}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Nationality}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {SupervisorName}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {AccountStatus}
                                            </p>
                                        </td>

                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-purple-300 hover:bg-purple-500 active:bg-purple-500  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-user text-white dark:text-purple-400 fs-16'} />
                                                </button>
                                                <button onClick={onOpen} className="action-btn border-blue-300 text-blue-300 me-3 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2
                                             text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-lock fs-16 '} />
                                                </button>
                                                <button onClick={employeeDetails} className="action-btn me-3 bg-blue-300 hover:bg-blue-400 active:bg-blue-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'}  />
                                                </button>
                                                <button className="action-btn bg-light hover:bg-grayBlue active:bg-grayBlue dark:border-navy-400 flex items-center justify-center p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-pen fs-16 text-white'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                        </tbody>
                    </table>
                </div>)
                : (

                    <div className={'mobileView'}>
                        {TABLE_ROWS.map((body, rowIndex) => (
                            <div key={rowIndex} className="card-table my-3">
                                <div className="">
                                    {TABLE_HEAD.slice(0, -1).map((head, headIndex) => (
                                        <div className={'flex justify-between px-4'} key={headIndex}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {head}
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {body[head.replace(/ /g, '') as keyof EmployeeData]}
                                            </p>
                                        </div>
                                    ))}
                                    <div className={'flex justify-center pt-4'}>
                                            <button className="action-btn me-3 bg-purple-300 hover:bg-purple-500 active:bg-purple-500  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-user text-white dark:text-purple-400 fs-16'} />
                                            </button>
                                            <button onClick={onOpen} className="action-btn border-blue-300 text-blue-300 me-3 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2
                                             text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-lock fs-16 '} />
                                            </button>
                                            <button onClick={employeeDetails} className="action-btn me-3 bg-blue-300 hover:bg-blue-400 active:bg-blue-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'}  />
                                            </button>
                                            <button className="action-btn bg-light hover:bg-grayBlue active:bg-grayBlue dark:border-navy-400 flex items-center justify-center p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-pen fs-16 text-white'} />
                                            </button>
                                        </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }

            <ActivateEmployeeModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        </div>
    );
}