import isMobile from "../../../../../utils/mobileUtils";
import React from "react";

const TABLE_HEAD = ["W.O / N.O", "Project Code", "Project Name", "Project Location",
    "Survey", "Report", "Invoice","Customer Satisfaction Survey", "Actions" ];

type WorkTableData = {
    W_O_N_O: string,
    Project_Code: string,
    Project_Name: string,
    Project_Location: string,
};
const TABLE_ROWS: WorkTableData[] = [
    {
        W_O_N_O: "1",
        Project_Code: "CCDR",
        Project_Name: "Karsd",
        Project_Location: "Riyadh",
    },
    {
        W_O_N_O: "1",
        Project_Code: "CCDR",
        Project_Name: "Karsd",
        Project_Location: "Riyadh",
    },
    {
        W_O_N_O: "1",
        Project_Code: "CCDR",
        Project_Name: "Karsd",
        Project_Location: "Riyadh",
    },
];

export function WorkTable() {
    const isMobileView = isMobile();
    return (
        <div className="h-full w-full">
            { !isMobileView ?
                (   <div className="px-0 overflow-x-auto">
                    <table className="work-table w-full min-w-max table-auto text-left custom-table">
                        <thead className={' border-radius-20'}>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2"
                                >
                                <span className="font-normal text-white" >
                                    {head}
                                </span>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {TABLE_ROWS.map(
                            (
                                {
                                    W_O_N_O,
                                    Project_Code,
                                    Project_Name,
                                    Project_Location,

                                },
                                index,
                            ) => {
                                const isLast = index === TABLE_ROWS.length - 1;

                                const classes = isLast
                                    ? 'p-2'
                                    : "p-2 border-b border-blue-gray-50";
                                return (
                                    <tr>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <p className="font-medium text-gray-700 fs-16 ps-4">
                                                    {W_O_N_O}
                                                </p>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Project_Code}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Project_Name}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Project_Location}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-upload fs-14 text-blue-300'} />
                                                </button>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-upload fs-14 text-blue-300'} />
                                                </button>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-upload fs-14 text-blue-300'} />
                                                </button>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-upload fs-14 text-blue-300'} />
                                                </button>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button className="action-btn bg-light hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-pen fs-16 text-white'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                        </tbody>
                    </table>
                </div>)
                : (

                    <div className={'mobileView'}>
                        {TABLE_ROWS.map((body, rowIndex) => (
                            <div key={rowIndex} className="card-table my-3">
                                <div className="">

                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            W.O / N.O
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                           41
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Project Code
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            1545
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Project Name
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            Project Name
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Project Location
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            Project Location
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Survey
                                        </p>
                                        <div className={'flex'}>
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Report
                                        </p>
                                        <div className={'flex'}>
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Invoice
                                        </p>
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                                <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-upload fs-14 text-blue-300'} />
                                                </button>
                                            </div>
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Customer Satisfaction Survey
                                        </p>
                                        <div className={'flex'}>
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={'flex justify-center pt-4'}>
                                        <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                            <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                        </button>
                                        <button className="action-btn bg-light hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                            <em className={'icon-pen fs-16 text-white'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    );
}