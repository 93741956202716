
import Card from "components/card";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";

const WorkOrder = () => {
  return (
    <Card extra="px-5 py-4 card-style relative">
      <div className={'ps-4 md:absolute top-2'}>
        <p className={'font-semiBold fs-22 text-blue-800'}>Work order</p>
        <p className={'font-medium fs-14'}>Total Revenue</p>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden pt-5">
        <div className="h-full w-full">
          <LineChart
            chartOptions={lineChartOptionsTotalSpent}
            chartData={lineChartDataTotalSpent}
          />
        </div>
      </div>
    </Card>
  );
};

export default WorkOrder;
