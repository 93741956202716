import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React from "react";
import modalBg from './../../../../../../../assets/img/layout/modal-bottom.png';
import CustomSelect from "../../../../../../../components/select/select";
import {useNavigate} from "react-router-dom";

interface NewOrderModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}
interface PPMCheckListModalProps extends NewOrderModalProps {
    selectedPath: string | null;
}
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const PPMCheckListModal: React.FC<PPMCheckListModalProps> = ({ isOpen, onClose, selectedPath  }) => {
    const navigate = useNavigate();
    const handleConfirm = () => {

        if (selectedPath) {
            navigate(selectedPath)
            console.log(`Redirecting to ${selectedPath}`);
        }
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#181C30] !opacity-80" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] lg:min-w-[350px] !max-w-[95%]">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                    <ModalBody>
                        <div className="modal-body bg-white border-radius-40 px-[15px] md:px-[30px] pt-[35px] pb-[40px] max-w-[1080px] flex flex-col !z-[1004]"
                              style={{backgroundImage: `url(${modalBg})`}}>
                            <h1 className="mb-[20px] fs-24 text-blue-800 font-bold">Return request</h1>
                            <p className="mb-[20px] fs-20 text-blue-800 font-regular">Please select to whom you want to return the request</p>
                            <div className={'pb-14 pt-1'}>
                                <CustomSelect
                                    label="Return to"
                                    placeholder="Return to"
                                    options={sampleOptions}
                                />
                            </div>
                            <div className="flex justify-center">
                                <button onClick={handleConfirm} className="btn linear text-white bg-blue-800 px-5 py-3 text-base font-medium transition duration-200 hover:bg-blue-900 active:bg-blue-900 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
    )}
export default PPMCheckListModal;