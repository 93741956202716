import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface CustomDatePickerProps {
    label?: string;
    placeholder?: string;
}
const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ label, placeholder }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleChange = (date: React.SetStateAction<Date>) => {
        setSelectedDate(date);
    };

    return (
        <div className={'flex flex-col'}>
            {label && <label className={'font-medium fs-15 ps-3 dark:text-white'}>{label}</label>}
           <div className={'pt-1 w-full'}>
               <DatePicker  placeholderText={placeholder} selected={selectedDate} onChange={handleChange} />
           </div>
        </div>

    );
};

export default CustomDatePicker;
