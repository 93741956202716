import headerImg from "./../../../assets/img/layout/header.png"
import DonutChartCard from "./components/DonutChartCard";
import WorkOrder from "./components/workOrder";
const Dashboard = () => {
    return (
        <div>
            {/* Card widget */}
            <div>
                <img src={headerImg} alt="headerImg" className={'w-full h-[60px]'}/>
            </div>

            {/* Charts */}

            <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
                <div className="col-span-2">
                    <WorkOrder/>
                </div>
                <div className="pe-4">
                    <div className={'dashCard purple mb-4'}>
                        <p className={'fs-18 text-white font-bold'}>Number of </p>
                        <p className={'fs-18 text-white font-bold'}>work order </p>
                        <h2 className={'fs-50 text-white font-bold'}>45</h2>
                    </div>
                    <div className={'dashCard blue'}>
                        <p className={'fs-18 text-white font-bold'}>Number of </p>
                        <p className={'fs-18 text-white font-bold'}>work order </p>
                        <h2 className={'fs-50 text-white font-bold'}>45</h2>
                    </div>
                </div>

            </div>

            <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
                {/*<DonutChart />*/}
                <DonutChartCard />
            </div>
        </div>
    );
};

export default Dashboard;
