import isMobile from "../../../../../utils/mobileUtils";
import React from "react";

const TABLE_HEAD = ["Emp No.", "Full name", "Role", "Department", "Phone number", "Email", "Nationality", "Actions" ];
type EmployeeData = {
    EmpNo: string;
    FullName: string;
    Role: string;
    Department: string;
    PhoneNumber: string;
    Email: string;
    Nationality: string;
};
const TABLE_ROWS: EmployeeData[] = [
    {
        EmpNo: "1",
        FullName: "CCDR",
        Role: "Karsd",
        Department: "Riyadh",
        PhoneNumber: "Riyadh",
        Email: "Riyadh",
        Nationality: "Riyadh",
    },
    {
        EmpNo: "1",
        FullName: "Test",
        Role: "13456",
        Department: "Riyadh",
        PhoneNumber: "Riyadh",
        Email: "Test",
        Nationality: "Test",
    },
    {
        EmpNo: "1",
        FullName: "CCDR",
        Role: "Karsd",
        Department: "Riyadh",
        PhoneNumber: "Riyadh",
        Email: "Riyadh",
        Nationality: "Riyadh",
    },
    {
        EmpNo: "1",
        FullName: "CCDR",
        Role: "Karsd",
        Department: "Riyadh",
        PhoneNumber: "Riyadh",
        Email: "Riyadh",
        Nationality: "Riyadh",
    },
];

export function EmployeeDetailsTable() {
    const isMobileView = isMobile();
    return (
        <div className="h-full w-full">
            { !isMobileView ?
                (
                    <div className="px-0 overflow-x-auto">
                <table className="w-full min-w-max table-auto text-left custom-table">
                    <thead className={' border-radius-20'}>
                    <tr>
                        {TABLE_HEAD.map((head) => (
                            <th
                                key={head}
                                className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2"
                            >
                                <span className="font-normal text-white" >
                                    {head}
                                </span>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {TABLE_ROWS.map(
                        (
                            {
                                EmpNo,
                                FullName,
                                Role,
                                Department,
                                PhoneNumber,
                                Email,
                                Nationality,
                            },
                            index,
                        ) => {
                            const isLast = index === TABLE_ROWS.length - 1;

                            const classes = isLast
                                ? 'p-2'
                                : "p-2 border-b border-blue-gray-50";
                            return (
                                <tr key={EmpNo}>
                                    <td className={classes}>
                                        <div className="flex items-center gap-3">
                                            <p className="font-medium text-gray-700 fs-16 ps-4">
                                                {EmpNo}
                                            </p>
                                        </div>
                                    </td>
                                    <td className={classes}>
                                        <p className="font-medium text-gray-700 fs-16">
                                            {FullName}
                                        </p>
                                    </td>
                                    <td className={classes}>
                                        <p className="font-medium text-gray-700 fs-16">
                                            {Role}
                                        </p>
                                    </td>
                                    <td className={classes}>
                                        <p className="font-medium text-gray-700 fs-16">
                                            {Department}
                                        </p>
                                    </td>
                                    <td className={classes}>
                                        <p className="font-medium text-gray-700 fs-16">
                                            {PhoneNumber}
                                        </p>
                                    </td>
                                    <td className={classes}>
                                        <p className="font-medium text-gray-700 fs-16">
                                            {Email}
                                        </p>
                                    </td>
                                    <td className={classes}>
                                        <p className="font-medium text-gray-700 fs-16">
                                            {Nationality}
                                        </p>
                                    </td>

                                    <td className={classes}>
                                        <div className={'flex'}>
                                            <button className="action-btn bg-purple-400 hover:bg-purple-500 active:bg-purple-500  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-purple-400 fs-16'} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        },
                    )}
                    </tbody>
                </table>
            </div>
                )
                : (
                    <div className={'mobileView'}>
                        {TABLE_ROWS.map((body, rowIndex) => (
                            <div key={rowIndex} className="card-table my-3">
                                <div className="">
                                    {TABLE_HEAD.slice(0, -1).map((head, headIndex) => (
                                        <div className={'flex justify-between px-4'} key={headIndex}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {head}
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {body[head.replace(/ /g, '') as keyof EmployeeData]}
                                            </p>
                                        </div>
                                    ))}
                                    <div className={'flex justify-center pt-4'}>
                                        <button className="action-btn bg-purple-400 hover:bg-purple-500 active:bg-purple-500  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                            <em className={'icon-trash text-white dark:text-purple-400 fs-16'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    );
}