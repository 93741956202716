export const recommendedChartOptions = {
  labels: ["Extremely likely", "Likely", "Not sure", "Unlikely"],
  colors: ["#C58ADF", "#93C4EA", "#E6D1F4", "#B1BEE9"],
  chart: {
    width: "180px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: true,
    position: 'bottom',
  },
  dataLabels: {
    enabled: true,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: true,
      donut: {
        labels: {
          show: true,
        },
      },
    },
  },
  fill: {
    colors: ["#C58ADF", "#93C4EA", "#E6D1F4", "#B1BEE9"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#000000",
    },
  },
};
export const recommendedChartData = [42, 25, 18, 15];


export const StaffChartData = [
  {
    name: "Yes",
    data: [20, 30, 40],
  },
  {
    name: "No",
    data: [50, 10, 19],
  },

];

export const StaffChartOption = {
  colors: ["#0D45A3", "#2194F5"],
  legend: {
    show: true,
    position: 'bottom'
  },

  theme: {
    mode: "light",
  },
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },

  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#000000",
    },
    theme: "dark",
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
  grid: {
    show: true,
  },
  yaxis: {
    show: true,
    axisBorder: {
      show: true,
    },
  },

  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '13px',
      borderRadius: 10,
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: false,
    curve: "smooth",
    width: 2,
    colors: ['transparent']
  },
  xaxis: {
    categories: ["Hygienic", "Uniform on", "ID Card on"],
    show: true,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: false,
    },
  },

  fill: {
    colors: ["#0D45A3", "#2194F5"],
  },
};



export const ServiceChartData = [
  {
    name: "Excellent",
    data: [20, 30, 40, 20],
  },
  {
    name: "Good",
    data: [10, 35, 20, 15],
  },
  {
    name: "Needs improvement",
    data: [25, 15, 35, 40],
  },
  {
    name: "N/A",
    data: [45, 35, 25, 15],
  },
];

export const ServiceChartOption = {
  colors: ["#0D45A3", "#E6D1F4", "#C58ADF", "#8095CF"],
  legend: {
    show: true,
    position: 'bottom'
  },
  theme: {
    mode: "light",
  },
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
      backgroundColor: "#000000",
    },
    theme: "dark",
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
  grid: {
    show: true,
  },
  yaxis: {
    show: true,
    axisBorder: {
      show: true,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '13px',
      borderRadius: 10,
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: false,
    curve: "smooth",
    width: 2,
    colors: ['transparent']
  },
  xaxis: {
    categories: ["Hospitality", "Housekeeping", "Maintenance", "Washroom Hygiene"],
    show: true,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: false,
    },
  },
  fill: {
    colors: ["#0D45A3", "#E6D1F4", "#C58ADF", "#8095CF"],
  },

};

