import { PPMScheduleListTable} from "./components/PPMScheduleListTable";
import {useDisclosure} from "@chakra-ui/hooks";
import InputField from "../../../../../components/fields/InputField";
import CustomSelect from "../../../../../components/select/select";
import CustomDatePicker from "../../../../../components/fields/DatePicker";
import Filter from "../../../../../components/filter/Filter";

const sampleOptions = [
    {label: "option 1", value: "option-1"},
    {label: "option 2", value: "option-2"},
    {label: "option 3", value: "option-3"},
];

const PPMEngineer = () => {
    return (
    <div>
        <Filter>
         <div className={'filter dashed-border pb-3 pt-1'}>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 px-4 pb-3">
                <div>
                   <InputField
                       label="S/N"
                       placeholder="S/N"
                       id="S/N"
                       type="text"
                    />
               </div>
                <div>
                    <InputField
                        label="Project code"
                        placeholder="Project code"
                        id="ProjectCode"
                        type="text"
                    />
                </div>
                <div>
                     <CustomSelect
                         label="Project location"
                         placeholder="Project location"
                         options={sampleOptions}
                     />
                 </div>
                <div>
                     <CustomSelect
                         label="Type"
                         placeholder="Type"
                         options={sampleOptions}
                     />
                 </div>


                <div>
                    <CustomDatePicker label="Created after" placeholder="Created after" />
                </div>
                <div>
                    <CustomDatePicker label="Created before" placeholder="Created before" />
                </div>
                <div>
                    <CustomSelect
                        label="Assigned to"
                        placeholder="Created by"
                        options={sampleOptions}
                    />
                </div>
                <div>
                    <CustomSelect
                        label="Created by"
                        placeholder="Created by"
                        options={sampleOptions}
                    />
                </div>
                <div>
                    <CustomSelect
                        label="Frequency"
                        placeholder="Creator department"
                        options={sampleOptions}
                    />
                </div>
            </div>
        </div>
        </Filter>
      <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          <PPMScheduleListTable/>
      </div>
    </div>
  );
};


export default PPMEngineer;
