import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React, {useState} from "react";
import modalBg from '../../../../../assets/img/layout/modal-bottom.png';

interface NewOrderModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}


const ActivateEmployeeModal: React.FC<NewOrderModalProps> = ({ isOpen, onOpen, onClose }) => {
    const [isActive, setIsActive] = useState(true);
    const handleActivateClick = () => {
        setIsActive(true);
    };

    const handleDeactivateClick = () => {
        setIsActive(false);
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#181C30] !opacity-80" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] lg:min-w-[680px] !max-w-[95%]">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                    <ModalBody>
                        <div className="modal-body bg-white border-radius-40 px-[15px] md:px-[30px] pt-[35px] pb-[40px] max-w-[680px] flex flex-col !z-[1004]"
                              style={{backgroundImage: `url(${modalBg})`}}>
                            <div className={'overflow-y-auto'}>
                                <p className="mb-[5px] w-1 fs-24 text-blue-800 font-bold text-center">activate/desactivate</p>
                                <p className="mb-[20px] fs-19  font-regular text-center">Please select</p>

                                <div className={'flex justify-center pb-3 pt-1'}>
                                    <button
                                        className={`btn btnGroup ms-4 text-navy-500 font-semiBold fs-20 transition duration-200 hover:bg-navy-300/20 active:bg-navy-300/20   ${
                                            isActive ? 'bg-navy-300/20 active' : 'border border-navy-400/36'
                                        } dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30`}
                                        onClick={handleActivateClick}
                                    >
                                        Activate
                                    </button>
                                    <button
                                        className={`btn btnGroup ms-4 text-navy-500 font-semiBold fs-20 transition duration-200 hover:bg-navy-300/20 active:bg-navy-300/20 
                                    ${!isActive ? 'bg-navy-300/20 active' : 'border border-navy-400/36'} dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30`}
                                        onClick={handleDeactivateClick}
                                    >
                                        Deactivate
                                    </button>

                                </div>
                                <div className="flex justify-center pt-5" onClick={onClose}>
                                    <button className="btn linear text-white bg-blue-800 px-5 py-3 text-base font-medium transition duration-200 hover:bg-blue-900 active:bg-blue-900 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
    ); };
export default ActivateEmployeeModal;