import CustomSelect from "../../../../components/select/select";
import {useDisclosure} from "@chakra-ui/hooks";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import Checkbox from "../../../../components/checkbox";
import {useNavigate} from "react-router-dom";
import Filter from "../../../../components/filter/Filter";
import isMobile from "../../../../utils/mobileUtils";
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const Technician = () => {
    const navigate = useNavigate();
    const redirectToEngeneer  = () => {
        navigate('/private/work-order-report');
    };
const isMobileView =isMobile();
  return (
    <div>
        <Filter>
          <div className={'filter dashed-border pb-3 pt-1'}>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 px-4 pb-3">
                <div>
                    <CustomSelect
                        label="Project code"
                        placeholder="Project code"
                        options={sampleOptions}
                    />
                </div>
                <div>
                    <InputField
                        disabled={true}
                        label="Project name"
                        placeholder="Project name"
                        id="ProjectName"
                        type="text"
                    />
                </div>
                <div>
                    <InputField
                        disabled={true}
                        label="Projet location"
                        placeholder="Projet location"
                        id="ProjetLocation"
                        type="text"
                    />
                </div>
                <div>
                    <InputField
                        disabled={true}
                        label="Contact person name"
                        placeholder="Contact person name"
                        id="ContactName"
                        type="text"
                    />
                </div>
                <div>
                    <InputField
                        disabled={true}
                        label="Telephone"
                        placeholder="Telephone"
                        id="Telephone"
                        type="text"
                    />
                </div>
                <div>
                    <InputField
                        disabled={true}
                        label="Working hours"
                        placeholder="Working hours"
                        id="WorkingHours"
                        type="text"
                    />
                </div>

            </div>
        </div>
        </Filter>
        <div>
            <div className="mt-3 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6 pb-3">
                <div className={'col-span-6 lg:col-span-2 simple-card p-3'}>
                    <div className={'flex justify-between pt-2 ps-3'}>
                        <p className={'font-semiBold fs-14 text-navy-400'}>Engineer (Mechanical/ Electrical / Civil)</p>
                        <button className="simple-addBtn bg-grayBlue hover:bg-navy-600 active:bg-navy-600 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200   font-medium text-white transition duration-200 dark:text-white">
                            <em className={'icon-plus text-white'}></em>
                        </button>
                    </div>


                    { !isMobileView ?
                        (       <div className={'overflow-x-auto'}>
                            <table className="work-table w-full min-w-max table-auto text-left custom-table">
                                <thead className={' border-radius-20'}>
                                <tr >
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                    Number
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Working hours
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Actions
                                </span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr >
                                    <td className={'py-2'}>
                                        <div className="flex items-center gap-3">
                                            <p className="font-regular text-gray-700 fs-14 ps-4">
                                                1
                                            </p>
                                        </div>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-1">
                                            25
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <div className={'flex'}>
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>)
                        : (

                            <div className={'mobileView'}>
                                <div className="card-table my-3">
                                    <div className="">
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Number
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                1
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Working hours
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-center px-4 py-1'} >
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
                <div className={'col-span-6 lg:col-span-4 simple-card p-3'}>
                    <div className={'flex justify-between pt-2 ps-3'}>
                        <p className={'font-semiBold fs-14 text-navy-400'}>Electrical: Electrical System/ Lighting System / Timer / Stabilizer</p>
                        <button className="simple-addBtn bg-grayBlue hover:bg-navy-600 active:bg-navy-600 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200   font-medium text-white transition duration-200 dark:text-white">
                            <em className={'icon-plus text-white'}></em>
                        </button>
                    </div>
                    { !isMobileView ?
                        ( <div className={'overflow-x-auto'}>
                            <table className="work-table w-full min-w-max table-auto text-left custom-table">
                                <thead className={' border-radius-20'}>
                                <tr >
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                   SN
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Type of equipment
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Capacity
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Quantity
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Condition
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Remarks
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Action
                                </span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr >
                                    <td className={'py-2'}>
                                        <div className="flex items-center gap-3">
                                            <p className="font-regular text-gray-700 fs-14 ps-4">
                                                1
                                            </p>
                                        </div>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-1">
                                            Spot Light
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-3">
                                            25
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-2">
                                            15
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14">
                                            Good
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14">
                                            All the
                                            equipment
                                            is
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <div className={'flex'}>
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>)
                        : (

                            <div className={'mobileView'}>
                                <div className="card-table my-3">
                                    <div className="">
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                SN
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                               1
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Type of equipment
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Spot Light
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Capacity
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Quantity
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Condition
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Good
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Remarks
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                All the equipment
                                            </p>
                                        </div>
                                        <div className={'flex justify-center px-4 py-1'} >
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className={'col-span-6 simple-card p-3'}>
                    <div className={'flex justify-between pt-2 ps-3'}>
                        <p className={'font-semiBold fs-14 text-navy-400'}>Mechanical : Air condition Units (Chiller / Package / Split / Window ) Etc.</p>
                        <button className="simple-addBtn bg-grayBlue hover:bg-navy-600 active:bg-navy-600 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200   font-medium text-white transition duration-200 dark:text-white">
                            <em className={'icon-plus text-white'}></em>
                        </button>
                    </div>


                    { !isMobileView ?
                        (   <div className={'overflow-x-auto'}>
                            <table className="work-table w-full min-w-max table-auto text-left custom-table">
                                <thead className={' border-radius-20'}>
                                <tr >
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                   SN
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Type of equipment
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Brand
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Capacity/ Ton
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Quantity
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Conditions
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Remarks
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Action
                                </span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr >
                                    <td className={'py-2'}>
                                        <div className="flex items-center gap-3">
                                            <p className="font-regular text-gray-700 fs-14 ps-4">
                                                1
                                            </p>
                                        </div>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-1">
                                            Spot Light
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-3">
                                            25
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-2">
                                            15
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14">
                                            Good
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14">
                                            ___
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14">
                                            All the
                                            equipment
                                            is
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <div className={'flex'}>
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>)
                        : (

                            <div className={'mobileView'}>
                                <div className="card-table my-3">
                                    <div className="">
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                SN
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                1
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Type of equipment
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Spot Light
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Brand
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Capacity/ Ton
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Quantity
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Condition
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Good
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Remarks
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                All the equipment
                                            </p>
                                        </div>
                                        <div className={'flex justify-center px-4 py-1'} >
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                </div>
                <div className={'col-span-6 lg:col-span-3 simple-card p-3'}>
                    <div className={'flex justify-between pt-2 ps-3'}>
                        <p className={'font-semiBold fs-14 text-navy-400'}>Signages / Flex Face / Neon / Pylon (Stand Alone) / Exit & Entry Signs Etc.</p>
                        <button className="simple-addBtn bg-grayBlue hover:bg-navy-600 active:bg-navy-600 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200   font-medium text-white transition duration-200 dark:text-white">
                            <em className={'icon-plus text-white'}></em>
                        </button>
                    </div>
                    { !isMobileView ?
                        (<div className={'overflow-x-auto'}>
                            <table className="work-table w-full min-w-max table-auto text-left custom-table">
                                <thead className={' border-radius-20'}>
                                <tr >
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                   SN
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2" style={{maxWidth:'100px'}}>
                                <span className="font-normal text-white" >
                                 Description / Type
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Qty
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Height
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Size (L * B = M²)
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Remarks
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Action
                                </span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr >
                                    <td className={'py-2'}>
                                        <div className="flex items-center gap-3">
                                            <p className="font-regular text-gray-700 fs-14 ps-4">
                                                1
                                            </p>
                                        </div>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-1">
                                            Spot Light
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-3">
                                            25
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-2">
                                            15
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14">
                                            Good
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14">
                                            ___
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <div className={'flex'}>
                                            <button className="action-btn me-2 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-2 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>)
                        : (

                            <div className={'mobileView'}>
                                <div className="card-table my-3">
                                    <div className="">
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                SN
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                1
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Description / Type
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Spot Light
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Qty
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Height
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Size (L * B = M²)
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Remarks
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Good
                                            </p>
                                        </div>
                                        <div className={'flex justify-center px-4 py-1'} >
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className={'col-span-6 lg:col-span-3 simple-card p-3'}>
                    <div className={'flex justify-between pt-2 ps-3'}>
                        <p className={'font-semiBold fs-14 text-navy-400'}>Others : Generator / Eelevator / Fire pump / Barriers Etc.</p>
                        <button className="simple-addBtn bg-grayBlue hover:bg-navy-600 active:bg-navy-600 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200   font-medium text-white transition duration-200 dark:text-white">
                            <em className={'icon-plus text-white'}></em>
                        </button>
                    </div>


                    { !isMobileView ?
                        (  <div className={'overflow-x-auto'}>
                            <table className="work-table w-full min-w-max table-auto text-left custom-table">
                                <thead className={' border-radius-20'}>
                                <tr >
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                   SN
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2" style={{maxWidth:'100px'}}>
                                <span className="font-normal text-white" >
                                  Type of equipment
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Brand
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Capacity
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Conditions
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Remarks
                                </span>
                                    </th>
                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                <span className="font-normal text-white" >
                                  Action
                                </span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr >
                                    <td className={'py-2'}>
                                        <div className="flex items-center gap-3">
                                            <p className="font-regular text-gray-700 fs-14 ps-4">
                                                1
                                            </p>
                                        </div>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-1">
                                            Fire pump
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-3">
                                            Mitsu
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-3">
                                            25
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14 ps-2">
                                            ----
                                        </p>
                                    </td>
                                    <td className={'py-2'}>
                                        <p className="font-regular text-gray-700 fs-14">
                                            ---
                                        </p>
                                    </td>

                                    <td className={'py-2'}>
                                        <div className={'flex'}>
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>)
                        : (

                            <div className={'mobileView'}>
                                <div className="card-table my-3">
                                    <div className="">
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                SN
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                1
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Type of equipment
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Spot Light
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Brand
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Capacity
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Conditions
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                25
                                            </p>
                                        </div>
                                        <div className={'flex justify-between px-4 py-1'} >
                                            <p className="text-blue-800 font-semiBold fs-16">
                                                Remarks
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                Good
                                            </p>
                                        </div>
                                        <div className={'flex justify-center px-4 py-1'} >
                                            <button className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-upload fs-14 text-blue-300'} />
                                            </button>
                                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <p className={'font-medium fs-15 pb-4 pt-3'}>Critically located Equipement / Machine andother observations which effect the cost and additional informations or suggestions:</p>
            <div className={'simple-card p-4 min-h-28 '}>
                <p className={'font-regular fs-12'}>
                    Critically located Equipement / Machine andother observations which effect the cost and additional informations or suggestions:
                </p>
            </div>
        </div>
        <div className={'flex justify-center pt-10 flex-wrap'}>
            <button className="btn border border-navy-500 border-navy-500 px-5 py-[12px] me-2 font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10">
                Cancel
            </button>
            <button onClick={redirectToEngeneer} className="btn bg-navy-500 md:ms-2 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                <span>Submit</span>
            </button>
        </div>
    </div>
  );
};


export default Technician;
