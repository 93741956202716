// IncrementDecrement.tsx

import React, { useState } from 'react';

interface IncrementDecrementProps {
  minValue?: number;
  maxValue?: number | undefined;
}

const IncrementDecrement: React.FC<IncrementDecrementProps> = ({
                                                                 minValue = 0,
                                                                 maxValue,
                                                               }) => {
  const [count, setCount] = useState(minValue);

  const handleIncrement = () => {
    if (maxValue === undefined || count < maxValue) {
      setCount(count + 1);
    }
  };

  const handleDecrement = () => {
    if (count > minValue) {
      setCount(count - 1);
    }
  };

  return (
      <div className="flex items-center">
        <button
            className="bg-white border border-blue-400/30 px-2 text-blue-400 py-1 border-radius-5 me-1 hover:text-white hover:bg-blue-200"
            onClick={handleDecrement}
        >
          <span className={' fs-17'}>-</span>
        </button>
        <input
            type="number"
            value={count}
            className="text-center flex justify-center ps-3 w-12 bg-white border border-blue-400/30 py-1 border-radius-5 mx-1"
            readOnly
        />
        <button
            className="bg-white border border-blue-400/30 px-2 text-blue-400 py-1 border-radius-5 me-1 hover:text-white hover:bg-blue-200"
            onClick={handleIncrement}
        >
            <span className={'fs-17'}>+</span>
        </button>
      </div>
  );
};

export default IncrementDecrement;
