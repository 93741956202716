import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import routes from "routes";
import isMobile from "../../utils/mobileUtils";

export default function Private(props: { [x: string]: any }) {
  const isMobileDevice = isMobile();
  const { ...rest } = props;
  const location = useLocation();
  // const [open, setOpen] = React.useState(true);
  const [open, setOpen] = React.useState(!isMobileDevice);
  const [currentRoute, setCurrentRoute] = React.useState("Dashboard");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
        window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
          window.location.href.indexOf(
              routes[i].layout + "/" + routes[i].path
          ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/private") {
        return (
            <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
      <div className="flex h-full w-full">
        <Sidebar open={open} onClose={() => setOpen(false)} />
        {/* Navbar & Main Content */}
        <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
          {/* Main Content */}
          <main
              className={`mx-[12px] h-full flex-none transition-all md:pr-2 my-6  md:pr-2 ${open ? 'xl:ml-[280px]' : 'xl:ml-[110px]'}`}>
            {/* Routes */}
            <div className="h-full bg-white  dark:!bg-navy-900 border-radius-15 pb-5">
              <Navbar
                  onOpenSidenav={() => setOpen(!open)}
                  open={open}
                  brandText={currentRoute}
                  secondary={getActiveNavbar(routes)}
                  {...rest}
              />
              <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] py-2 px-3 md:pr-3">
                <Routes>
                  {getRoutes(routes)}
                  <Route
                      path="/"
                      element={<Navigate to="/private/dashboard" replace />}
                  />
                </Routes>
              </div>
              {/*<div className="p-3">*/}
              {/*  <Footer />*/}
              {/*</div>*/}
            </div>
          </main>
        </div>
      </div>
  );
}
