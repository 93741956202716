import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React from "react";
import modalBg from '../../../../../assets/img/layout/modal-bottom.png';
import CustomSelect from "../../../../../components/select/select";
import {useNavigate} from "react-router-dom";

interface NewOrderModalProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const NewOrderModal: React.FC<NewOrderModalProps> = ({ isOpen, onOpen, onClose }) => {
    const navigate = useNavigate();
    const redirectToSupervisor  = () => {
        navigate('/private/work-order/request');
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#181C30] !opacity-80" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] lg:min-w-[680px] !max-w-[95%]">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                    <ModalBody>
                        <div className="modal-body bg-white border-radius-40 px-[15px] md:px-[30px] pt-[35px] pb-[40px] max-w-[680px] flex flex-col !z-[1004]"
                              style={{backgroundImage: `url(${modalBg})`}}>
                            <h1 className="mb-[20px] fs-24 text-blue-800 font-bold">WORK Order Request</h1>
                            <div className={'pb-3 pt-1'}>
                                <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 px-4 pb-3">
                                    <CustomSelect
                                        label="W.O/N.O"
                                        placeholder="W.O/N.O"
                                        options={sampleOptions}
                                    />
                                    <CustomSelect
                                        label="Project code"
                                        placeholder="Project code"
                                        options={sampleOptions}
                                    />
                                    <CustomSelect
                                        label="Project name"
                                        placeholder="Project name"
                                        options={sampleOptions}
                                    />
                                    <CustomSelect
                                        label="Project location"
                                        placeholder="Project location"
                                        options={sampleOptions}
                                    />
                                </div>
                                <div className={'px-4'}>
                                    <label className={'font-medium fs-15 ps-3'}>Nature of work</label>
                                    <textarea placeholder="Nature of work"
                                              className="flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                              name="textarea" id="textarea">

                                    </textarea>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <button onClick={redirectToSupervisor} className="btn linear text-white bg-blue-800 px-5 py-3 text-base
                                 font-medium transition duration-200 hover:bg-blue-900 active:bg-gray-300
                                  dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
    ); };
export default NewOrderModal;