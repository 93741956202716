import RecommendedChart from "./components/RecommendedChart";
import InputField from "../../../../components/fields/InputField";
import CustomDatePicker from "../../../../components/fields/DatePicker";
import React from "react";
import StaffChart from "./components/StaffChart";
import ServiceChart from "./components/ServicesChart";
import Progress from "../../../../components/progress";

const WorkRequestResult = () => {
  return (
    <div>

        <div className={'grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-2'}>
            <div className={'col-span-1'}>
                <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-7 px-4 pb-5">
                    <div className={'col-span-3'}>
                        <InputField
                            label="Location"
                            placeholder="Location"
                            id="Location"
                            type="text"
                        />
                    </div>
                    <div className={'col-span-2'}>
                        <CustomDatePicker label="From" placeholder="From" />
                    </div>
                    <div className={'col-span-2'}>
                        <CustomDatePicker label="To" placeholder="To" />
                    </div>
                </div>
                <div className="map-element border-radius-21 overflow-hidden">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1813.0954538568005!2d46.7217453!3d24.6515563!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f044c1b7721e1%3A0xd130abf717db116e!2sREMB2778%2C%202778%20Ibn%20Zaian%2C%208385%2C%20Al%20Malaz%2C%20Riyadh%2012641%2C%20Saudi%20Arabia!5e0!3m2!1sen!2seg!4v1695555034214!5m2!1sen!2seg"
                        width="100%" height="350" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade" className="Capture"></iframe>
                </div>
            </div>
            <div className={'col-span-1'}>
                <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1 2xl:grid-cols-2 px-4 pb-3">
                    <div className={'col-span-1'}>
                        <div className={'border-radius-21 overflow-hidden shadow-card-2'}>
                            <div className={'cardHeader bg-blue-100 p-3'}>
                                <p className={'text-blue-300 font-medium fs-20'}>Recommended or not</p>
                            </div>
                            <div className={'cardBody bg-white p-3'}>
                                <RecommendedChart/>
                            </div>
                        </div>
                    </div>
                    <div className={'col-span-1'}>
                        <div className={'border-radius-21 overflow-hidden shadow-card-2'}>
                            <div className={'cardHeader bg-pink-300 p-3'}>
                                <p className={'text-white font-medium fs-19'}>Recommended or not</p>
                            </div>
                            <div className={'cardBody bg-white p-3'}>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>1</span>
                                    <Progress value={10} color="pink" />
                                </div>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>2</span>
                                    <Progress value={80} color="pink" />
                                </div>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>3</span>
                                    <Progress value={70} color="pink" />
                                </div>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>4</span>
                                    <Progress value={40} color="pink" />
                                </div>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>5</span>
                                    <Progress value={60} color="pink" />
                                </div>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>6</span>
                                    <Progress value={80} color="pink" />
                                </div>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>7</span>
                                    <Progress value={25} color="pink" />
                                </div>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>8</span>
                                    <Progress value={70} color="pink" />
                                </div>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>9</span>
                                    <Progress value={85} color="pink" />
                                </div>
                                <div className={'flex items-center'}>
                                    <span className={'font-medium pe-3'}>10</span>
                                    <Progress value={10} color="pink" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={'col-span-1'}>
                <div className={'border-radius-21 overflow-hidden shadow-card-2'}>
                    <div className={'cardHeader bg-pink-100 p-3'}>
                        <p className={'text-white font-medium fs-20'}>Services</p>
                    </div>
                    <div className={'cardBody bg-white p-3'}>
                        <ServiceChart/>
                    </div>
                </div>
            </div>
            <div className={'col-span-1'}>
                <div className={'border-radius-21 overflow-hidden shadow-card-2'}>
                    <div className={'cardHeader bg-navy-200 p-3'}>
                        <p className={'text-white font-medium fs-20'}>Staff</p>
                    </div>
                    <div className={'cardBody bg-white p-3'}>
                        <StaffChart/>
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
};






export default WorkRequestResult;
