import { Routes, Route, Navigate } from "react-router-dom";

import PrivateLayout from "layouts/private";
import Home from "layouts/public/home/home";
import AuthLayout from "layouts/auth";
import PublicLayout from "./layouts/public/home/home";

const App = () => {
  return (
    <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="/*" element={<Home/>} />
        <Route path="public/*" element={<PublicLayout/>} />
        <Route path="private/*" element={<PrivateLayout />} />
        <Route path="/" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default App;
