import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import isMobile from "../../../utils/mobileUtils";


export const SidebarLinks = (props: { routes: any; onClose: any; }): JSX.Element => {
  const isMobileDevice = isMobile();
  let location = useLocation();
  const { routes, onClose } = props;

  const customBoxShadow = '10px 7px 16px 0px rgba(99, 180, 245, 0.39)';
  const customBorderRadius = '0 21px 0 0';

    const [activeParent, setActiveParent] = useState<string | null>(null);

    const isActive = (route: RoutesType): boolean => {
        // Check if the current route is active
        if (location.pathname.includes(route.path)) {
            return true;
        }

        // Check if any child link is active
        if (route.children) {
            return route.children.some((child: { path: string; }) => location.pathname.includes(child.path));
        }

        return false;
    };
    const closeSideBarMobile = () => {
        // Close the sidebar when a link is clicked
        if (isMobileDevice && onClose){
                onClose();
        }

    };
    const handleParentClick = (path: string): void => {
        setActiveParent((prevPath) => (prevPath === path ? null : path));
    };

    const renderChildren = (parentPath: string, children: RoutesType[] | undefined): JSX.Element | null => {
        if (activeParent === parentPath) {
            return (
                <ul className="ml-4">
                    {children?.map((child, index) => (
                        <li key={index} className="mb-2 ps-5">
                            <Link onClick={closeSideBarMobile}
                                className={`link hover:text-blue-500 ${onClose ? "ps-0" : "ps-12"}  ${isActive(child) ? "active text-blue-500" : ""}`}
                                to={child.layout + "/" + child.path}
                            >
                                {child.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            );
        }
        return null;
    };


    const createLinks = (routes: RoutesType[]) => {

        return routes.map((route, index) => {
            if (route.layout === "/private" && route.show !== false) {
                const commonContent = (
                    <div
                        className={`sidebarLink relative py-2 flex hover:cursor-pointer hover:bg-blue-300 ${
                            isActive(route) ? "bg-blue-300 dark:text-navy-500" : "dark:text-white "
                        }`}
                        style={{
                            boxShadow: isActive(route) ? customBoxShadow : 'none',
                            borderRadius: isActive(route) ? customBorderRadius : '0',
                        }}
                    >
                        <li
                            className={'my-[3px] flex cursor-pointer items-center px-8 sidebar-link'}
                            key={index}>
                                <span
                                    className={`flex items-center ${
                                        isActive(route) ? "text-white dark:text-navy-500" : "text-blue-800 dark:text-white"
                                    }`}
                                >
                                    {route.icon ? route.icon : <DashIcon />}
                                </span>
                                <p
                                    className={`leading-1 ml-4 flex fs-16 ${
                                        isActive(route) ? "font-semiBold text-white dark:text-navy-500" : "font-medium text-blue-800 dark:text-white"
                                    }`}
                                >
                                    {route.name}
                                </p>
                        </li>
                    </div>
                );

                // Conditionally render a Link or a plain div based on route.isParent
                if (route.isParent !== true) {
                    return (
                        <Link onClick={closeSideBarMobile}
                            className={`link ${isActive(route) ? "active" : ""}`}
                            key={index}
                            to={route.layout + "/" + route.path}
                        >
                            {commonContent}
                        </Link>
                    );
                } else {
                    return (
                        <div
                            className={`link parent ${isActive(route) ? "active" : ""}`}
                            key={index}

                        >
                            <div  onClick={() => handleParentClick(route.path)}>
                                   {commonContent}
                            </div>
                            {renderChildren(route.path, route.children)}
                        </div>
                    );
                }
            }
            return null;
        });

    };

    return <>{createLinks(routes)}</>;
};

export default SidebarLinks;
