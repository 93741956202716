
import {useDisclosure} from "@chakra-ui/hooks";
import React, {useState} from "react";
import PPMCheckListModal from "./components/PPMScheduleCheckListModal/PPMScheduleCheckListModal";
import {useNavigate} from "react-router-dom";

interface CardData {
    icon?: string;
    title?: string;
    path?: string;
}
const PPMScheduleCheckList: React.FC<CardData> = ({}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);


    const data = [
        { icon: 'icon-electrical', title: 'Electrical', path: '/private/ppm-technician/ppm-checkList/electrical-maintenance' },
        { icon: 'icon-air', title: 'Airconditioning', path: '/private/ppm-technician/ppm-checkList/airconditionning-maintenance' },
        { icon: 'icon-plumb', title: 'Plumbing', path: '/private/ppm-technician/ppm-checkList/plumbing-maintenance' },
        { icon: 'icon-irrigation', title: 'Irrigation system', path: '/private/ppm-technician/ppm-checkList/irrigation-maintenance' },
        { icon: 'icon-swimming', title: 'Swimming pool', path: '/private/ppm-technician/ppm-checkList/swimming-maintenance' },
    ];

    const handleCardClick = (index: number, path: string) => {
        setActiveIndex(index);
        console.log('Clicked on:', path);
    };

    return (
        <div>
            <div className={'flex justify-center pb-8 flex-wrap'}>
                <div className={'dashed-border py-4 px-6 mx-4 mb-2'}>
                    <span className={'font-medium fs-15 pe-8'}>Project code</span>
                    <span className={'font-semiBold fs-16'}>C014 </span>
                </div>

            </div>
            <div className="mt-3 checkListCard grid grid-cols-2 gap-2 md:gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 px-0 md:px-10 pb-3">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`shadow simple-card  ${index === activeIndex ? 'active p-3 md:p-8' : 'p-3 md:p-8'}`}
                        onClick={() => handleCardClick(index, item.path)}
                    >
                        <div className={'flex justify-center fs-80'}>
                            <em className={` ${item.icon}`}></em>
                        </div>
                        <p className={"font-semiBold text-center fs-16 pt-10"}>{item.title}</p>
                    </div>
                ))}
            </div>
            <div className={'pt-8 md:px-10'}>
                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Note*</label>
                <textarea placeholder="Note*"
                          className="flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                          name="textarea" id="textarea">

                                    </textarea>
            </div>
            <div className={'flex justify-center py-8'}>

                <button className="btn border border-navy-500 border-navy-500 px-5 py-[12px] me-2 font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10">
                    Cancel
                </button>
                <button onClick={onOpen} className="btn linear bg-navy-500 py-[12px] ms-2 font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                    Complete
                </button>

            </div>
            <PPMCheckListModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} selectedPath={activeIndex !== null ? data[activeIndex].path : null} />
        </div>
    );
};


export default PPMScheduleCheckList;
