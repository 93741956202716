import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React from "react";
import modalBg from '../../../../../assets/img/layout/modal-bottom.png';
import CustomSelect from "../../../../../components/select/select";
import InputField from "../../../../../components/fields/InputField";

interface addItemModalProps {
    isOpen: boolean;
    openAssignTechnicianModal: () => void;
    onOpen: () => void;
    onClose: () => void;
}
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const addItemModal: React.FC<addItemModalProps> = ({ isOpen, openAssignTechnicianModal, onClose }) => {
    const handleAssignTechnician = () => {
        onClose();
        openAssignTechnicianModal();
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#181C30] !opacity-80" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[200px] lg:min-w-[650px] !max-w-[95%]">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                <ModalBody className={''}>
                    <div className="modal-body bg-white border-radius-40 px-[15px] md:px-[30px] pt-[35px] pb-[40px] min-w-[95vw] md:min-w-[500px] max-w-[100%] flex flex-col !z-[1004] "
                         style={{backgroundImage: `url(${modalBg})`}}>
                        <div>
                            <h1 className="fs-24 text-blue-800 font-bold">Add item</h1>
                            <p>Please fill the following form</p>
                            <div className={'pb-3 pt-1'}>
                                <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 px-4 pb-3">
                                    <div>
                                        <InputField
                                            label="Item"
                                            placeholder="Item"
                                            id="Item"
                                            type="text"
                                        />
                                    </div>
                                    <div>
                                        <CustomSelect
                                            label="Category"
                                            placeholder="Category"
                                            options={sampleOptions}
                                        />
                                    </div>

                                    <div>
                                        <InputField
                                            label="Price"
                                            placeholder="Price"
                                            id="Price"
                                            type="text"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button onClick={handleAssignTechnician} className="btn linear text-white bg-blue-800 px-5 py-3 text-base
                                 font-medium transition duration-200 hover:bg-blue-900 active:bg-gray-300
                                  dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                                Add
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    ); };
export default addItemModal;