import DonutChart from "components/charts/Donut";
import CustomSelect from "../../../../../components/select/select";
import React from "react";
import {recommendedChartData, recommendedChartOptions} from "./charts-data";
const dateOption = [
  { label: "Monthly", value: "Monthly" },
  { label: "Yearly", value: "Yearly" },
  { label: "Weekly", value: "Weekly" },
];
const RecommendedChart = () => {
  return (
    <div>
      <div className="flex flex-row justify-end px-3 mb-3">
        <CustomSelect
            options={dateOption}
        />
      </div>

      <div className="h-[300px]">
        <DonutChart chartOptions={recommendedChartOptions} chartData={recommendedChartData} />
      </div>
      </div>
  );
};

export default RecommendedChart;
