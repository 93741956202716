
import CustomSelect from "../../../../components/select/select";
import InputField from "../../../../components/fields/InputField";
import React from "react";
import Radio from "../../../../components/radio";
import {useNavigate} from "react-router-dom";
import Filter from "../../../../components/filter/Filter";
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const Engineer = () => {

    const navigate = useNavigate();
    const redirectToResult  = () => {
        navigate('/private/customer-satisfaction');
    };

  return (
    <div>
        <Filter>
             <div className={'filter pb-3 pt-1'}>
            <p className={'font-bold fs-20 pb-4 dark:text-white'}>Project details</p>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                <div>
                    <CustomSelect
                        label="Client’s name"
                        placeholder="Client’s name"
                        options={sampleOptions}
                    />
                </div>
                <div>
                    <InputField
                        label="Work Order No"
                        placeholder="Work Order No"
                        id="WorkOrderNo"
                        type="text"
                    />
                </div>
                <div>
                    <CustomSelect
                        label="Work Location"
                        placeholder="Work Location"
                        options={sampleOptions}
                    />
                </div>
                <div>
                    <InputField
                        label="Job Card No"
                        placeholder="Job Card No"
                        id="JobCardNo"
                        type="text"
                    />
                </div>
                <div>
                    <InputField
                        label="Date Received"
                        placeholder="Date Received"
                        id="DateReceived"
                        type="text"
                    />
                </div>
                <div>
                    <InputField
                        label="Date Required"
                        placeholder="Date Required"
                        id="DateRequired"
                        type="text"
                    />
                </div>
                <div>
                    <CustomSelect
                        label="Nature of Work / Problem"
                        placeholder="Nature of Work / Problem"
                        options={sampleOptions}
                    />
                </div>
                <div className={'col-span-2'}>
                    <label className={'font-medium fs-15 ps-3 dark:text-white'}>Corrective actions taken</label>
                    <textarea placeholder="Nature of work"
                              className="flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                              name="textarea" id="textarea">

                                    </textarea>
                </div>
                <div className={'col-span-2'}>
                    <label className={'font-medium fs-15 ps-3 dark:text-white'}>Brief description</label>
                    <textarea placeholder="Nature of work"
                              className="flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                              name="textarea" id="textarea">

                                    </textarea>
                </div>
            </div>
        </div>
        </Filter>
        <div className={'flex justify-end pt-4 flex-wrap'}>
            <button  className="btn bg-navy-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                <em className={'icon-plus'}></em>
                <span className={'ps-3'}>Add Item</span>
            </button>
        </div>
        <div className={'overflow-x-auto'}>
            <table className="work-table w-full min-w-max table-auto text-left custom-table">
                <thead className={' border-radius-20'}>
                <tr >
                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                        <span className="font-normal text-white" >
                            Materials used
                        </span>
                    </th>
                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                        <span className="font-normal text-white" >
                         Name of Tech.
                        </span>
                    </th>
                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                        <span className="font-normal text-white" >
                          Emp No.
                        </span>
                    </th>
                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                        <span className="font-normal text-white" >
                          Man Hrs
                        </span>
                    </th>
                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                        <span className="font-normal text-white" >
                          Actions
                        </span>
                    </th>

                </tr>
                </thead>
                <tbody>
                <tr >
                    <td className={'py-2'}>
                        <div className="flex items-center gap-3">
                            <p className="font-regular text-gray-700 fs-14 ps-4">
                                1
                            </p>
                        </div>
                    </td>
                    <td className={'py-2'}>
                        <p className="font-regular text-gray-700 fs-14 ps-1">
                           Spot Light
                        </p>
                    </td>
                    <td className={'py-2'}>
                        <p className="font-regular text-gray-700 fs-14 ps-1">
                           25
                        </p>
                    </td>
                    <td className={'py-2'}>
                        <p className="font-regular text-gray-700 fs-14 ps-1">
                            15
                        </p>
                    </td>
                    <td className={'py-2'}>
                        <div className={'flex'}>
                            <button className="action-btn me-3 border-blue-300 hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                <em className={'icon-upload fs-14 text-blue-300'} />
                            </button>
                            <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="mt-3 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 px-4 pb-3">
                <div className={'flex justify-between bg-white shadow-card px-8 py-5 border-radius-26'}>
                    <p className={'font-bold fs-16'}>Date & Time started</p>
                    <p className={'font-medium fs-16'}>03/06/2023</p>
                </div>
                <div className={'flex justify-between bg-white shadow-card px-8 py-5 border-radius-26'}>
                    <p className={'font-bold fs-16'}>Date & Time completed</p>
                    <p className={'font-medium fs-16'}>03/06/2023</p>
                </div>
            </div>
            <div className="dashed-border mt-3 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 px-4 py-5 pb-3">

                <div className={' '}>
                    <p className={'font-bold fs-16 dark:text-white'}> Customer’s comments</p>
                    <div className={'flex'}>
                        <div className={'me-5'}>
                            <Radio label={'Service to my satisfaction'} id="blue" name="color" color="blue" defaultChecked />
                        </div>
                        <div>
                            <Radio label={'Service not to my satisfaction'} id="red" name="color" color="red" />
                        </div>
                    </div>
                </div>
                <div className={''}>
                    <div className={'col-span-2'}>
                        <label className={'font-medium fs-15 ps-3 dark:text-white'}>Corrective actions taken</label>
                        <textarea placeholder="Nature of work"
                                  className="flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                  name="textarea" id="textarea">

                                    </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div className={'flex justify-center pt-10 flex-wrap'}>
            <button className="btn border border-navy-500 border-navy-500 px-5 py-[12px] me-2 font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10">
                Cancel
            </button>
            <button onClick={redirectToResult} className="btn bg-navy-500 md:ms-2 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                <span>Submit</span>
            </button>
        </div>
        <div className="mt-8 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 px-4 pb-3">
            <div className={'simple-card py-4'}>
                <div className="grid grid-cols-1 gap-5 grid-cols-2 pb-3 px-4 md:ps-7 pe-5">
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Action</p>
                        <p className={'font-medium fs-15'}>Requested by</p>
                    </div>
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Date</p>
                        <p className={'font-medium fs-15 text-gray-700'}>28/02/2023-21:03</p>
                    </div>
                    <div className={'col-span-2'}>
                        <p className={'text-blue-800 font-semiBold fs-16 '}>Name- Department/ Section- Emp No.</p>
                        <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                        <p className={'font-medium fs-13  text-gray-700/30'}>Maintenance in charge</p>
                    </div>

                </div>
                <div className={'border-top-blue px-4 md:ps-7 pt-2'}>
                    <p className={'text-blue-800 font-semiBold fs-16 '}>Comment</p>
                    <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                </div>
            </div>
            <div className={'simple-card py-4'}>
                <div className="grid grid-cols-1 gap-5 grid-cols-2 pb-3 px-4 md:ps-7 pe-5">
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Action</p>
                        <p className={'font-medium fs-15'}>Requested by</p>
                    </div>
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Date</p>
                        <p className={'font-medium fs-15 text-gray-700'}>28/02/2023-21:03</p>
                    </div>
                    <div className={'col-span-2'}>
                        <p className={'text-blue-800 font-semiBold fs-16 '}>Name- Department/ Section- Emp No.</p>
                        <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                        <p className={'font-medium fs-13  text-gray-700/30'}>Maintenance in charge</p>
                    </div>

                </div>
                <div className={'border-top-blue px-4 md:ps-7 pt-2'}>
                    <p className={'text-blue-800 font-semiBold fs-16 '}>Comment</p>
                    <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                </div>
            </div>
            <div className={'simple-card py-4'}>
                <div className="grid grid-cols-1 gap-5 grid-cols-2 pb-3 px-4 md:ps-7 pe-5">
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Action</p>
                        <p className={'font-medium fs-15'}>Requested by</p>
                    </div>
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Date</p>
                        <p className={'font-medium fs-15 text-gray-700'}>28/02/2023-21:03</p>
                    </div>
                    <div className={'col-span-2'}>
                        <p className={'text-blue-800 font-semiBold fs-16 '}>Name- Department/ Section- Emp No.</p>
                        <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                        <p className={'font-medium fs-13  text-gray-700/30'}>Maintenance in charge</p>
                    </div>

                </div>
                <div className={'border-top-blue px-4 md:ps-7 pt-2'}>
                    <p className={'text-blue-800 font-semiBold fs-16 '}>Comment</p>
                    <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                </div>
            </div>
        </div>
    </div>
  );
};


export default Engineer;
