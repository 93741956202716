import InputField from "../../../../../../components/fields/InputField";
import CustomSelect from "../../../../../../components/select/select";
import {useNavigate} from "react-router-dom";
import React from "react";
import Filter from "../../../../../../components/filter/Filter";


const sampleOptions = [
    {label: "option 1", value: "option-1"},
    {label: "option 2", value: "option-2"},
    {label: "option 3", value: "option-3"},
];

const PpmApprove= () => {
    const navigate = useNavigate();
    const backToManger = () => {
        navigate('/private/ppm_manager');
    };
    return (
        <div>
            <Filter>
                <div className={'filter dashed-border pb-3 pt-1'}>
                    <div className="mt-3   gap-5 flex flex-wrap px-4 pb-3">
                        <div className={'border-r border-blue-200 px-4'}>
                            <span className={'font-medium fs-15 pe-4'}>S/N</span>
                            <span className={'font-medium fs-15 ps-4'}>Riadh</span>
                        </div>
                        <div className={'border-r border-blue-200 px-4'}>
                            <span className={'font-medium fs-15 pe-4'}>Project location</span>
                            <span className={'font-medium fs-15 ps-4'}>Riyadh</span>
                        </div>
                        <div className={'border-r border-blue-200 px-4'}>
                            <span className={'font-medium fs-15 pe-4'}>Project code</span>
                            <span className={'font-medium fs-15 ps-4'}>C014</span>
                        </div>
                        <div className={'border-r border-blue-200 px-4'}>
                            <span className={'font-medium fs-15 pe-4'}>Type</span>
                            <span className={'font-medium fs-15 ps-4'}>PPM</span>
                        </div>
                        <div className={'border-r border-blue-200 px-4'}>
                            <span className={'font-medium fs-15 pe-4'}>For the period</span>
                            <span className={'font-medium fs-15 ps-4'}>2023 To 2024</span>
                        </div>
                        <div className={'border-r border-blue-200 px-4'}>
                            <span className={'font-medium fs-15 pe-4'}>Frequency</span>
                            <span className={'font-medium fs-15 ps-4'}>Quarterly</span>
                        </div>
                        <div className={'border-r border-blue-200 px-4'}>
                            <span className={'font-medium fs-15 pe-4'}>Contract start date</span>
                            <span className={'font-medium fs-15 ps-4'}>1-1-2023</span>
                        </div>
                        <div className={'px-4'}>
                            <span className={'font-medium fs-15 pe-4'}>Contract end date</span>
                            <span className={'font-medium fs-15 ps-4'}>1-1-2024</span>
                        </div>
                    </div>
                </div>
            </Filter>


            <p className="mb-[20px] fs-15 font-semiBold text-blue-800 dark:text-white pt-5">Frequency</p>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-4 pb-3 custom-radius">
                <div className={'flex items-center dashed-border p-3'}>
                    <div className={'flex items-center'}>
                        <span className={'font-medium'} >Q1</span>
                    </div>
                    <div className={'flex ps-3 w-full'}>
                        <div className={'w-10'}>
                            <InputField
                                placeholder="1"
                                id=""
                                type="text"
                            />
                        </div>
                        <div className={'ps-2 w-full'}>
                            <CustomSelect
                                placeholder=""
                                options={sampleOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className={'flex items-center dashed-border p-3'}>
                    <div className={'flex items-center'}>
                        <span className={'font-medium'} >Q2</span>
                    </div>
                    <div className={'flex ps-3 w-full'}>
                        <div className={'w-10'}>
                            <InputField
                                placeholder="1"
                                id=""
                                type="text"
                            />
                        </div>
                        <div className={'ps-2 w-full'}>
                            <CustomSelect
                                placeholder=""
                                options={sampleOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className={'flex items-center dashed-border p-3'}>
                    <div className={'flex items-center'}>
                        <span className={'font-medium'} >Q3</span>
                    </div>
                    <div className={'flex ps-3 w-full'}>
                        <div className={'w-10'}>
                            <InputField
                                placeholder="1"
                                id=""
                                type="text"
                            />
                        </div>
                        <div className={'ps-2 w-full'}>
                            <CustomSelect
                                placeholder=""
                                options={sampleOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className={'flex items-center dashed-border p-3'}>
                    <div className={'flex items-center'}>
                        <span className={'font-medium'} >Q4</span>
                    </div>
                    <div className={'flex ps-3 w-full'}>
                        <div className={'w-10'}>
                            <InputField
                                placeholder="1"
                                id=""
                                type="text"
                            />
                        </div>
                        <div className={'ps-2 w-full'}>
                            <CustomSelect
                                placeholder=""
                                options={sampleOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={''}>
                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Any other comment’s</label>
                <textarea placeholder="Findings "
                          className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                          name="textarea" id="textarea">
                                    </textarea>
            </div>
            <div className={'flex justify-center pt-8 pb-4'}>

                <button onClick={backToManger} className="btn border border-navy-500 border-navy-500 px-5 py-[12px] me-2 font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10">
                    Cancel
                </button>
                <button className="btn linear bg-navy-500 py-[12px] ms-2 font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                    Submit
                </button>

            </div>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 px-4 pb-3">
                <div className={'simple-card py-4'}>
                    <div className="grid grid-cols-1 gap-5 grid-cols-2 pb-3 px-4 md:ps-7 pe-5">
                        <div>
                            <p className={'text-blue-800 font-semiBold fs-16'}>Action</p>
                            <p className={'font-medium fs-15'}>Requested by</p>
                        </div>
                        <div>
                            <p className={'text-blue-800 font-semiBold fs-16'}>Date</p>
                            <p className={'font-medium fs-15 text-gray-700'}>28/02/2023-21:03</p>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'text-blue-800 font-semiBold fs-16 '}>Name- Department/ Section- Emp No.</p>
                            <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                            <p className={'font-medium fs-13  text-gray-700/30'}>Maintenance in charge</p>
                        </div>

                    </div>
                    <div className={'border-top-blue px-4 md:ps-7 pt-2'}>
                        <p className={'text-blue-800 font-semiBold fs-16 '}>Comment</p>
                        <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                    </div>
                </div>
                <div className={'simple-card py-4'}>
                    <div className="grid grid-cols-1 gap-5 grid-cols-2 pb-3 px-4 md:ps-7 pe-5">
                        <div>
                            <p className={'text-blue-800 font-semiBold fs-16'}>Action</p>
                            <p className={'font-medium fs-15'}>Requested by</p>
                        </div>
                        <div>
                            <p className={'text-blue-800 font-semiBold fs-16'}>Date</p>
                            <p className={'font-medium fs-15 text-gray-700'}>28/02/2023-21:03</p>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'text-blue-800 font-semiBold fs-16 '}>Name- Department/ Section- Emp No.</p>
                            <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                            <p className={'font-medium fs-13  text-gray-700/30'}>Maintenance in charge</p>
                        </div>

                    </div>
                    <div className={'border-top-blue px-4 md:ps-7 pt-2'}>
                        <p className={'text-blue-800 font-semiBold fs-16 '}>Comment</p>
                        <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                    </div>
                </div>
                <div className={'simple-card py-4'}>
                    <div className="grid grid-cols-1 gap-5 grid-cols-2 pb-3 px-4 md:ps-7 pe-5">
                        <div>
                            <p className={'text-blue-800 font-semiBold fs-16'}>Action</p>
                            <p className={'font-medium fs-15'}>Requested by</p>
                        </div>
                        <div>
                            <p className={'text-blue-800 font-semiBold fs-16'}>Date</p>
                            <p className={'font-medium fs-15 text-gray-700'}>28/02/2023-21:03</p>
                        </div>
                        <div className={'col-span-2'}>
                            <p className={'text-blue-800 font-semiBold fs-16 '}>Name- Department/ Section- Emp No.</p>
                            <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                            <p className={'font-medium fs-13  text-gray-700/30'}>Maintenance in charge</p>
                        </div>

                    </div>
                    <div className={'border-top-blue px-4 md:ps-7 pt-2'}>
                        <p className={'text-blue-800 font-semiBold fs-16 '}>Comment</p>
                        <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                    </div>
                </div>
            </div>

        </div>
    );
};


export default PpmApprove;
