import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from "@chakra-ui/modal";
import React from "react";
import modalBg from '../../../../../assets/img/layout/modal-bottom.png';
import CustomSelect from "../../../../../components/select/select";
import Checkbox from "../../../../../components/checkbox";
import Pagination from "../../../../../components/pagination";
import Filter from "../../../../../components/filter/Filter";
import isMobile from "../../../../../utils/mobileUtils";


interface NewOrderModalProps {
    isOpen: boolean;
    openAddItemModal : () => void;
    onClose: () => void;
    onOpen: (addItem: string) => any;
}
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const NewOrderModal: React.FC<NewOrderModalProps> = ({ isOpen, onClose, openAddItemModal }) => {
    const handleAddItem = () => {
        onClose();
        openAddItemModal();
    };
    const isMobileView = isMobile();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#181C30] !opacity-80" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[200px] lg:min-w-[650px] !max-w-[95%]">
                     <span className={'close-modal cursor-pointer w-[35px] h-[35px] bg-red-500 dark:bg-navy-200 flex items-center justify-center'}   onClick={onClose}>
                       <em className={'icon-close fs-20 text-white dark:text-white'}></em>
                     </span>
                    <ModalBody className={''}>
                        <div className="modal-body bg-white border-radius-40 ps-[10px] pe-[5px] md:ps-[30px] md:pe-[10px] pt-[35px] pb-[40px] max-w-[100%] flex flex-col !z-[1004] "
                              style={{backgroundImage: `url(${modalBg})`}}>
                            <div className={'overflow-y-auto over overflow-x-hidden pe-3'}>
                                <div className={'flex justify-between pe-5 items-center flex-wrap'}>
                                    <div>
                                        <h1 className="fs-24 text-blue-800 font-bold">Add item</h1>
                                        <p>Please fill the following form</p>
                                    </div>
                                    <div>
                                        <p>Item  not  found? <span className={'text-blue-300 cursor-pointer'}  onClick={handleAddItem}>Click here</span></p>
                                    </div>
                                </div>

                                <Filter>
                                    <div className={'pb-3 pt-1'}>
                                        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-4 px-4 pb-3">
                                            <CustomSelect
                                                label="W.O/N.O"
                                                placeholder="W.O/N.O"
                                                options={sampleOptions}
                                            />
                                            <CustomSelect
                                                label="Project code"
                                                placeholder="Project code"
                                                options={sampleOptions}
                                            />
                                            <CustomSelect
                                                label="Project name"
                                                placeholder="Project name"
                                                options={sampleOptions}
                                            />
                                            <CustomSelect
                                                label="Project location"
                                                placeholder="Project location"
                                                options={sampleOptions}
                                            />
                                        </div>
                                        <div className={'px-4'}>
                                            <label className={'font-medium fs-15 ps-3'}>Nature of work</label>
                                            <textarea placeholder="Nature of work"
                                                      className="flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                                      name="textarea" id="textarea">

                                    </textarea>
                                        </div>
                                    </div>
                                </Filter>



                                { !isMobileView ?
                                    (    <div className={'overflow-x-auto'}>
                                            <table className="work-table w-full min-w-max table-auto text-left custom-table">
                                                <thead className={' border-radius-20'}>
                                                <tr >
                                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                    <span className="font-normal text-white" >
                                                        Description of Material / Item / Spare parts
                                                    </span>
                                                    </th>
                                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                    <span className="font-normal text-white" >
                                                      Category
                                                    </span>
                                                    </th>
                                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                    <span className="font-normal text-white" >
                                                      Provider
                                                    </span>
                                                    </th>
                                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                    <span className="font-normal text-white" >
                                                      Availability
                                                    </span>
                                                    </th>
                                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                    <span className="font-normal text-white" >
                                                      Phone number
                                                    </span>
                                                    </th>
                                                    <th className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2">
                                                    <span className="font-normal text-white" >
                                                      Price (SAR)
                                                    </span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr >
                                                    <td className={'py-2'}>
                                                        <div className="flex items-center gap-3">
                                                            <p className="font-regular text-gray-700 fs-14 ps-4">
                                                                Tube Light
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-1">
                                                            Electricity
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-3">
                                                            Spare company
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-2">
                                                            Yes
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14">
                                                            +966 0569475
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <div className={'flex justify-between px-2'}>
                                                            <p className="font-regular text-gray-700 fs-14">
                                                                66
                                                            </p>
                                                            <Checkbox color="green" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className={'py-2'}>
                                                    <td className='py-2'>
                                                        <p className="font-regular text-gray-700 fs-14 ps-4">
                                                            Tube Light
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-1">
                                                            Electricity
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-3">
                                                            Spare company
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-2">
                                                            Yes
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14">
                                                            +966 0569475
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <div className={'flex justify-between px-2'}>
                                                            <p className="font-regular text-gray-700 fs-14">
                                                                66
                                                            </p>
                                                            <Checkbox color="green" defaultChecked />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='py-2'>
                                                        <p className="font-regular text-gray-700 fs-14 ps-4">
                                                            Tube Light
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-1">
                                                            Electricity
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-3">
                                                            Spare company
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-2">
                                                            Yes
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14">
                                                            +966 0569475
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <div className={'flex justify-between px-2'}>
                                                            <p className="font-regular text-gray-700 fs-14">
                                                                66
                                                            </p>
                                                            <Checkbox color="green" defaultChecked />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='classes'>
                                                        <div className="flex items-center gap-3">
                                                            <p className="font-regular text-gray-700 fs-14 ps-4">
                                                                Tube Light
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-1">
                                                            Electricity
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-3">
                                                            Spare company
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14 ps-2">
                                                            Yes
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <p className="font-regular text-gray-700 fs-14">
                                                            +966 0569475
                                                        </p>
                                                    </td>
                                                    <td className={'py-2'}>
                                                        <div className={'flex justify-between px-2'}>
                                                            <p className="font-regular text-gray-700 fs-14">
                                                                66
                                                            </p>
                                                            <Checkbox color="green" defaultChecked />
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>)
                                    : (

                                        <div className={'mobileView'}>
                                            <div className="card-table my-3">
                                                    <div className="">
                                                        <div className={'flex justify-between px-4 py-1'} >
                                                            <p className="text-blue-800 font-semiBold fs-16">
                                                                Description of Material / Item / Spare parts
                                                            </p>
                                                            <p className="font-medium text-gray-700 fs-16">
                                                                Tube Light
                                                            </p>
                                                        </div>
                                                        <div className={'flex justify-between px-4 py-1'} >
                                                            <p className="text-blue-800 font-semiBold fs-16">
                                                                Category
                                                            </p>
                                                            <p className="font-medium text-gray-700 fs-16">
                                                                Electricity
                                                            </p>
                                                        </div>

                                                        <div className={'flex justify-between px-4 py-1'} >
                                                            <p className="text-blue-800 font-semiBold fs-16">
                                                                Provider
                                                            </p>
                                                            <p className="font-medium text-gray-700 fs-16">
                                                                Spare company
                                                            </p>
                                                        </div>
                                                        <div className={'flex justify-between px-4 py-1'} >
                                                            <p className="text-blue-800 font-semiBold fs-16">
                                                                Availability
                                                            </p>
                                                            <p className="font-medium text-gray-700 fs-16">
                                                                Yes
                                                            </p>
                                                        </div>
                                                        <div className={'flex justify-between px-4 py-1'} >
                                                            <p className="text-blue-800 font-semiBold fs-16">
                                                                Phone number
                                                            </p>
                                                            <p className="font-medium text-gray-700 fs-16">
                                                                +966 0569475
                                                            </p>
                                                        </div>
                                                        <div className={'flex justify-between px-4 py-1'} >
                                                            <p className="text-blue-800 font-semiBold fs-16">
                                                                Price (SAR)
                                                            </p>
                                                            <div className={'flex justify-between ps-2'}>
                                                                <p className="font-regular text-gray-700 fs-14 pe-4">
                                                                    66
                                                                </p>
                                                                <div className={'ps-3'}>
                                                                    <Checkbox color="green" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    )
                                }

                                <Pagination currentPage={1} totalPages={10} onPageChange={(page) => console.log(`Go to page ${page}`)} />
                            </div>
                            <div className="flex justify-center">
                                <button onClick={handleAddItem} className="btn linear text-white bg-blue-800 px-5 py-3 text-base
                                 font-medium transition duration-200 hover:bg-blue-900 active:bg-gray-300
                                  dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
    ); };
export default NewOrderModal;