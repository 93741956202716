
import CustomSelect from "../../../../../components/select/select";
import InputField from "../../../../../components/fields/InputField";
import { EmployeeDetailsTable } from "./components/employeeDetailsTable";
import Switch from "../../../../../components/switch";
import CheckCard from "../../../../../components/card/CheckCard";
import Checkbox from "components/checkbox";
import Filter from "components/filter/Filter";

const sampleOptions = [
    {label: "option 1", value: "option-1"},
    {label: "option 2", value: "option-2"},
    {label: "option 3", value: "option-3"},
];

const RolePermission = () => {
    return (
    <div>
        <Filter>
         <div className={'filter dashed-border pb-3 pt-1'}>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-6 pb-3">
                <div>
                     <CustomSelect
                         label="Role name"
                         placeholder="Role name"
                         options={sampleOptions}
                     />
                 </div>
                <div className={'flex items-center pt-7 ps-4'}>
                    <p className={'font-regular fs-15 pe-5'}>Approver ?</p>
                   <div className={'flex items-center'}>
                       <span className={'font-semiBold fs-15 text-blue-800 pe-4'}>Yes</span>
                       <Switch id="blue" color="blue"/>
                       <span className={'font-semiBold fs-15 text-blue-800  ps-4'}>No</span>
                   </div>
                </div>
            </div>
        </div>
        </Filter>
        <div className={'pt-4 pb-3'}>
            <h2 className={'font-bold fs-20 text-navy-500 dark:text-white'}>Permissions</h2>
        </div>
      <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-3">
          <div>
              <CheckCard
              headerContent={
                  <div>
                      <Checkbox color="navy" label={'Purchase requisition'}  />
                  </div>
              }
              bodyContent={
                  <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                      <div>
                          <Checkbox color="navy" label={'List'}  />
                      </div>
                      <div>
                          <Checkbox color="navy" label={'Add'}  />
                      </div>
                      <div>
                          <Checkbox color="navy" label={'Update'}  />
                      </div>
                      <div>
                          <Checkbox color="navy" label={'View'}  />
                      </div>
                  </div>
              }
              theme="light"
          />
          </div>
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Configuration'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                          <div>
                              <Checkbox color="navy" label={'List'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Add'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Update'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'View'}  />
                          </div>
                      </div>
                  }
                  theme="purple-400 purple"
              />
          </div>
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Configuration'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                          <div>
                              <Checkbox color="navy" label={'List'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Add'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Update'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'View'}  />
                          </div>
                      </div>
                  }
                  theme="blue-100 blue"
              />
          </div>
          <div>
              <CheckCard
              headerContent={
                  <div>
                      <Checkbox color="navy" label={'PPM Schedule for maintennace'}  />
                  </div>
              }
              bodyContent={
                  <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                      <div>
                          <Checkbox color="navy" label={'List'}  />
                      </div>
                      <div>
                          <Checkbox color="navy" label={'Add'}  />
                      </div>
                      <div>
                          <Checkbox color="navy" label={'Update'}  />
                      </div>
                      <div>
                          <Checkbox color="navy" label={'View'}  />
                      </div>
                  </div>
              }
              theme="indigo-200 indigo"
          />
          </div>
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Projects'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-3">
                          <div>
                              <Checkbox color="navy" label={'List'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Add'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Update'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'View'}  />
                          </div> 
                          <div>
                              <Checkbox color="navy" label={'Attachment download'}  />
                          </div>        
                          <div>
                              <Checkbox color="navy" label={'Attachment view'}  />
                          </div>
                      </div>
                  }
                  theme="pink-100 pink"
              />
          </div>
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Employees'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-3">
                          <div>
                              <Checkbox color="navy" label={'List'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Add'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Generate password'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'View'}  />
                          </div>       
                          <div>
                              <Checkbox color="navy" label={'Active/Desacticate'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Update'}  />
                          </div>
                      </div>
                  }
                  theme="cyan-200 cyan"
              />
          </div>    
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Work order'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-3">
                          <div>
                              <Checkbox color="navy" label={'List'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Export'}  />
                          </div>

                          <div>
                              <Checkbox color="navy" label={'Update'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Create request'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'View'}  />
                          </div>
                      </div>
                  }
                  theme="purple-400 purple"
              />
          </div>
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Spare parts'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                          <div>
                              <Checkbox color="navy" label={'List'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Add'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Edit'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'View'}  />
                          </div>
                      </div>
                  }
                  theme="indigo-200 indigo"
              />

          </div>   
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Customer satisfaction Survey- Results'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                          <div>
                              <Checkbox color="navy" label={'Export'}  />
                          </div>
                      </div>
                  }
                  theme="pink-400 pink"
              />

          </div>
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Report'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                          <div>
                              <Checkbox color="navy" label={'Export'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Create request'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Update'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'View'}  />
                          </div>
                      </div>
                  }
                  theme="cyan-200 cyan"
              />

          </div>
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Customer satisfaction Survey'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                          <div>
                              <Checkbox color="navy" label={'Review'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Export'}  />
                          </div>
                      </div>
                  }
                  theme="indigo-200 indigo"
              />

          </div>
          <div>
              <CheckCard
                  headerContent={
                      <div>
                          <Checkbox color="navy" label={'Maintenance Survey & Estimation Sheet'}  />
                      </div>
                  }
                  bodyContent={
                      <div className="grid h-full grid-cols-1 gap-5 md:grid-cols-2">
                          <div>
                              <Checkbox color="navy" label={'Export'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Create request'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'Update'}  />
                          </div>
                          <div>
                              <Checkbox color="navy" label={'View'}  />
                          </div>
                      </div>
                  }
                  theme="purple-400 purple"
              />

          </div>
      </div>
        <div className={'flex justify-center pt-10'}>
            <button className="btn border border-navy-500 border-navy-500 px-5 py-[12px] me-2 font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10">
                Cancel
            </button>
            <button className="btn linear bg-navy-500 py-[12px] ms-2 font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                Submit
            </button>
        </div>
    </div>
  );
};


export default RolePermission;
