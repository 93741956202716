import React, { ReactNode, useState } from 'react';
import isMobile from "../../utils/mobileUtils";

interface AccordionProps {
    children: ReactNode;
}

const Filter: React.FC<AccordionProps> = ({ children }) => {
    const isMobileView = isMobile();
    const [isOpen, setIsOpen] = useState(!isMobileView);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            {isMobileView &&
                <div
                    className="flex items-center justify-end cursor-pointer pb-3"
                    onClick={toggleAccordion}
                >
                    <div className={` border-radius-10 text-white px-3 pt-3 pb-2 ${isOpen ? 'bg-blue-500':'bg-blue-800'}`} >
                        <em className='icon-filter fs-25'></em>
                    </div>
                </div>
            }

            {isOpen && (
               <div>
                   {children}
               </div>
            )}
        </div>
    );
};

export default Filter;
