import Links from "./components/Links";
import { HiX } from "react-icons/hi";
import logo from './../../assets/img/layout/logo-colored.png'
import logoSm from './../../assets/img/layout/logo-colored-sm.png'
import sidebarImg from './../../assets/img/layout/side-bar.png'
import routes from "routes";

import React from "react";
import isMobile from "../../utils/mobileUtils";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  const isMobileView = isMobile();
  return (
      <div className={'relative'}>
        <div className={`sidebar sm:none duration-175 w-[275px] linear fixed bg-blue-50 md:bg-transparent overflow-auto !z-50 flex min-h-full flex-col shadow-2xl 
      shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
            open ? "translate-x-0" : "-translate-x-96 md:translate-x-0  closed-sidebar"
        }`}
        >
        <span
            className="absolute top-4 right-4 block cursor-pointer xl:hidden"
            onClick={onClose}
        >
          <HiX />
        </span>

          <div className={ `flex items-center  pt-8 pb-10 justify-center ${ !open ? 'pe-3' : '' }`}>
            <img src={ `${ open ? logo : logoSm }`} alt="sidebarImg"/>
          </div>
          <div className={'sidebar-content'}>
            <ul className="mb-auto pt-1 max-h[70vh] overflow-auto">
              <Links routes={routes} onClose={onClose} />
            </ul>
            <div className={`p-5 ${ !open ? 'hidden' : '' }`}
                 style={{ backgroundImage: `url(${sidebarImg})` , backgroundSize:'cover' , backgroundRepeat:'no-repeat'}}>
              <p className={'pb-16 pt-5 font-regular fs-20 text-white'}>Need Help ?</p>
              <div className={'ps-4 pt-1'}>
                <button  className="btn linear text-blue-800 bg-white px-5 py-3 text-base mt-8
                                 font-medium transition duration-200 hover:bg-gray-300 active:bg-gray-300
                                  dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
        {open && isMobileView &&  <span  onClick={onClose} className={'overlay md:hidden duration-175  min-h-full'}/>}

      </div>
  );
};

export default Sidebar;
