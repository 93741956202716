import isMobile from "../../../../utils/mobileUtils";
import React from "react";


const TABLE_HEAD = ["Dep No", "Department", "Manager", "Actions" ];
type SettingTableData = {
    DepNo:string,
    Department: string,
    Manager: string,
};
const TABLE_ROWS:SettingTableData[] = [
    {
        DepNo: "1",
        Department: "CCDR",
        Manager: "Karsd",
    },
    {
        DepNo: "1",
        Department: "CCDR",
        Manager: "Karsd",
    },
    {
        DepNo: "1",
        Department: "CCDR",
        Manager: "Karsd",
    },
    {
        DepNo: "1",
        Department: "CCDR",
        Manager: "Karsd",
    },
    {
        DepNo: "1",
        Department: "CCDR",
        Manager: "Karsd",
    },

];

export function SettingsTable() {
    const isMobileView = isMobile();
    return (
        <div className="h-full w-full">
            { !isMobileView ?
                (   <div className="px-0 overflow-x-auto">
                        <table className="w-full min-w-max table-auto text-left custom-table setting-table">
                        <thead className={' border-radius-20'}>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2"
                                >
                            <span className="font-normal text-white" >
                                {head}
                            </span>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {TABLE_ROWS.map(
                            (
                                {
                                    DepNo,
                                    Department,
                                    Manager,

                                },
                                index,
                            ) => {
                                const isLast = index === TABLE_ROWS.length - 1;

                                const classes = isLast
                                    ? 'p-2'
                                    : "p-2 border-b border-blue-gray-50";
                                return (
                                    <tr key={DepNo}>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <p className="font-medium text-gray-700 fs-16 ps-4">
                                                    {DepNo}
                                                </p>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Department}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {Manager}
                                            </p>
                                        </td>
                                        <td className={classes}>
                                            <div className={'flex'}>
                                                <button className="action-btn me-3 bg-light hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-pen fs-16 text-white'} />
                                                </button>
                                                <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                    <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                        </tbody>
                    </table>
                    </div>
                )
                : (
                    <div className={'mobileView'}>
                        {TABLE_ROWS.map((body, rowIndex) => (
                            <div key={rowIndex} className="card-table my-3">
                                <div className="">
                                    {TABLE_HEAD.slice(0, -1).map((head, headIndex) => (
                                        <div className={'flex justify-between px-4'} key={headIndex}>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {head}
                                            </p>
                                            <p className="font-medium text-gray-700 fs-16">
                                                {body[head.replace(/ /g, '') as keyof SettingTableData]}
                                            </p>
                                        </div>
                                    ))}
                                    <div className={'flex justify-center pt-4'}>
                                        <button className="action-btn me-3 bg-light hover:bg-navy-600/10 active:bg-navy-700/10 dark:border-navy-400 flex items-center justify-center border p-2 text-3xl transition duration-200 hover:cursor-pointer dark:bg-navy-200 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                            <em className={'icon-pen fs-16 text-white'} />
                                        </button>
                                        <button className="action-btn  bg-purple-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                            <em className={'icon-trash text-white dark:text-navy-500 fs-16'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    );
}