import React from "react";
import Select, { Props as SelectProps } from "react-select";

interface CustomSelectProps extends SelectProps {
    label?: string;
    placeholder?: string;
    minWidth?: string;
    isDisabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ label, placeholder,isDisabled, minWidth, ...props }) => {
    return (
        <div className={'CustomSelect dark:dark-mode dark:text-white'}>
            {label && <label className={'font-medium fs-15 ps-3 dark:text-white'}>{label}</label>}
            <Select
                className={`custom-select pt-1 ${isDisabled ? 'disabled' : ''}`}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                placeholder={placeholder}
                isDisabled={isDisabled}
                {...props}
            />
        </div>
    );
};

export default CustomSelect;
