import { MdFileUpload } from "react-icons/md";
import { useState } from 'react';
import Card from "components/card";

const Upload = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDragEnter = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: { preventDefault: () => void; dataTransfer: { files: any; }; }) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;

    if (files.length > 0) {
      // Update the state with the selected file name
      setSelectedFile(files[0].name);
      // Handle additional file upload logic if needed
    }
  };

  const handleFileInputChange = (e: { target: { files: any; }; }) => {
    const files = e.target.files;

    if (files.length > 0) {
      // Update the state with the selected file name
      setSelectedFile(files[0].name);
      // Handle additional file upload logic if needed
    }
  };
  return (
      <Card
          className={`py-3 dark:!bg-navy-800 `}
          onDragEnter={handleDragEnter}
          onDragOver={(e: { preventDefault: () => any; }) => e.preventDefault()}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
      >
        <div className="max-w-[450px] col-span-5 h-full w-full dashed-border px-5 py-3 bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6 bg-red.500">
          <label
              htmlFor="fileInput"
              className="flex h-full w-full flex-col items-center justify-center rounded-xl  py-3 dark:!border-navy-700 lg:pb-0 cursor-pointer"
          >
            <input
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileInputChange}
                // Add any necessary file input attributes or event handlers here
            />
            <div className={'flex items-center pb-4'}>
              <em className={'icon-upload text-blue-800 fs-30'}></em>
              <div className={'ps-4'}>
                <h4 className="fs-14 font-regular dark:text-white">
                  Drag and drop your files here or  <span className={'font-semiBold text-blue-800'}>Choose files</span>
                </h4>
                {selectedFile ? (
                    <p className="mt-2 text-sm font-medium text-gray-600">
                      {selectedFile}
                    </p>
                ) : (
                    <p className="mt-2 text-sm font-medium text-gray-600">
                      5 MB max file size, jpg, jpeg or png only are accepted
                    </p>
                )}
              </div>
            </div>
          </label>
        </div>
      </Card>
  );
};

export default Upload;
