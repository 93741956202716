import { EmployeesTable} from "./components/employeesTable";
import CustomSelect from "../../../../components/select/select";
import {useDisclosure} from "@chakra-ui/hooks";
import InputField from "../../../../components/fields/InputField";
import NewEmployeeModal from "./components/newEmployeeModal";
import Filter from "../../../../components/filter/Filter";

const sampleOptions = [
    {label: "option 1", value: "option-1"},
    {label: "option 2", value: "option-2"},
    {label: "option 3", value: "option-3"},
];

const Employees = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
    <div>
        <Filter>
            <div className={'filter dashed-border pb-3 pt-1'}>
                <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 px-4 pb-3">
                    <div>
                        <InputField
                            label="Emp No."
                            placeholder="Emp No."
                            id="EmpNo"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label="Full Name"
                            placeholder="Full Name"
                            id="FullName"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomSelect
                            label="Role"
                            placeholder="Role"
                            options={sampleOptions}
                        />
                    </div>
                    <div>
                        <InputField
                            label="Department"
                            placeholder="Department"
                            id="Department"
                            type="text"
                        />
                    </div>
                    <div>
                        <InputField
                            label="Phone number"
                            placeholder="Phone number"
                            id="Telephone"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomSelect
                            label="Account status"
                            placeholder="Account status"
                            options={sampleOptions}
                        />
                    </div>
                    <div>
                        <InputField
                            label="Email"
                            placeholder="Email"
                            id="Email"
                            type="text"
                        />
                    </div>
                    <div>
                        <CustomSelect
                            label="Nationality"
                            placeholder="Nationality"
                            options={sampleOptions}
                        />
                    </div>
                    <div>
                        <CustomSelect
                            label="Project location"
                            placeholder="Project location"
                            options={sampleOptions}
                        />
                    </div>
                </div>
            </div>
        </Filter>
        <div className={'flex justify-end pt-4'}>
            <button onClick={onOpen} className="btn bg-navy-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                <em className={'icon-plus'}></em>
                <span className={'ps-3'}>New employee</span>
            </button>
        </div>
      <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          <EmployeesTable/>
      </div>
        <div className={'flex justify-center pt-4'}>

            <button className="btn border border-navy-500 border-navy-500 px-5 py-[12px] me-2 font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10">
                Cancel
            </button>
            <button className="btn linear bg-navy-500 py-[12px] ms-2 font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                Submit
            </button>
        </div>
        <NewEmployeeModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </div>
  );
};


export default Employees;
