import {WorkRequestTable} from "./components/WorkRequestTable";
import CustomSelect from "../../../../components/select/select";
import NewOrderModal from "./components/newOrderModal";
import AddItemModal from "./components/addItemModal";
import AssignTechnicianModal from "./components/AssignTechnicianModal";

import React, {useState} from "react";
import Filter from "../../../../components/filter/Filter";
const sampleOptions = [
    { label: "option 1", value: "option-1" },
    { label: "option 2", value: "option-2" },
    { label: "option 3", value: "option-3" },
];

const Supervisor = () => {

    const [isOpenNewOrderModal, setIsOpenNewOrderModal] = useState(false);
    const [isOpenAddItemModal, setIsOpenAddItemModal] = useState(false);
    const [isOpenAssignTechnicianModal, setIsOpenAssignTechnicianModal] = useState(false);

    const openNewOrderModal = () => {
        setIsOpenNewOrderModal(true);
    };

    const closeNewOrderModal = () => {
        setIsOpenNewOrderModal(false);
    };

    const openAddItemModal = () => {
        setIsOpenAddItemModal(true);
    };

    const closeAddItemModal = () => {
        setIsOpenAddItemModal(false);
    };
    const openAssignTechnicianModal = () => {
        setIsOpenAssignTechnicianModal(true);
    };

    const closeAssignTechnicianModal = () => {
        setIsOpenAssignTechnicianModal(false);
    };
  return (
    <div>
        <Filter>
         <div className={'filter dashed-border pb-3 pt-1'}>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
              <div>
                  <CustomSelect
                      isDisabled={true}
                      label="W.O/N.O"
                      placeholder="W.O/N.O"
                      options={sampleOptions}
                  />
              </div>
                <div>
                    <CustomSelect
                        isDisabled={true}
                        label="Project code"
                        placeholder="Project code"
                        options={sampleOptions}
                    />
                </div>
                <div>
                    <CustomSelect
                        isDisabled={true}
                        label="Project name"
                        placeholder="Project name"
                        options={sampleOptions}
                    />
                </div>
                <div>
                    <CustomSelect
                        isDisabled={true}
                        label="Project location"
                        placeholder="Project location"
                        options={sampleOptions}
                    />
                </div>

                <div className={'col-span-2'}>
                    <label className={'font-medium fs-15 ps-3 pb-3'}>Nature of work</label>
                    <textarea placeholder="Nature of work" disabled={true}
                              className="mt-1 flex w-full items-center justify-center border-radius-22  bg-lightBlue pl-3 pt-3
                               outline-none dark:!border-white/10 dark:text-white"
                              name="textarea" id="textarea" value={'Replacement of Deffective Bathroom Sanitary Ware'}>
                    </textarea>
                </div>

            </div>
        </div>
        </Filter>
        <div className={'flex justify-end pt-4'}>
            <button onClick={openNewOrderModal} className="btn bg-light px-5 me-4 py-3 text-base font-medium text-white transition duration-200 hover:bg-grayBlue active:bg-grayBlue dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                <em className={'icon-plus'}></em>
                <span className={'ps-3'}>Forward for Survey</span>
            </button>
            <button onClick={openNewOrderModal} className="btn bg-navy-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                <em className={'icon-plus'}></em>
                <span className={'ps-3'}>Add item</span>
            </button>
        </div>
      <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          <WorkRequestTable/>
      </div>
        <div className={'px-4 pt-5'}>
            <label className={'font-medium fs-15 ps-3'}>Comment*</label>
            <textarea placeholder="Nature of work"
                      className="flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                      name="textarea" id="textarea">

                    </textarea>
        </div>
        <div className="flex justify-center pb-5 pt-8">
            <button className="btn linear text-blue-800 bg-white px-5 py-3 me-4 text-base border border-blue-900
                                 font-medium transition duration-200 hover:bg-blue-200 active:bg-blue-200
                                  dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                Reject
            </button>
            <button className="btn linear text-white bg-blue-800 px-5 py-3 text-base
                                 font-medium transition duration-200 hover:bg-blue-900 active:bg-blue-900
                                  dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30">
                Submit
            </button>
        </div>
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 px-4 pb-3">
            <div className={'simple-card py-4'}>
                <div className="grid grid-cols-1 gap-5 grid-cols-2 pb-3 px-4 md:ps-7 pe-5">
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Action</p>
                        <p className={'font-medium fs-15'}>Requested by</p>
                    </div>
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Date</p>
                        <p className={'font-medium fs-15 text-gray-700'}>28/02/2023-21:03</p>
                    </div>
                    <div className={'col-span-2'}>
                        <p className={'text-blue-800 font-semiBold fs-16 '}>Name- Department/ Section- Emp No.</p>
                        <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                        <p className={'font-medium fs-13  text-gray-700/30'}>Maintenance in charge</p>
                    </div>

                </div>
                <div className={'border-top-blue px-4 md:ps-7 pt-2'}>
                    <p className={'text-blue-800 font-semiBold fs-16 '}>Comment</p>
                    <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                </div>
            </div>
            <div className={'simple-card py-4'}>
                <div className="grid grid-cols-1 gap-5 grid-cols-2 pb-3 px-4 md:ps-7 pe-5">
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Action</p>
                        <p className={'font-medium fs-15'}>Requested by</p>
                    </div>
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Date</p>
                        <p className={'font-medium fs-15 text-gray-700'}>28/02/2023-21:03</p>
                    </div>
                    <div className={'col-span-2'}>
                        <p className={'text-blue-800 font-semiBold fs-16 '}>Name- Department/ Section- Emp No.</p>
                        <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                        <p className={'font-medium fs-13  text-gray-700/30'}>Maintenance in charge</p>
                    </div>

                </div>
                <div className={'border-top-blue px-4 md:ps-7 pt-2'}>
                    <p className={'text-blue-800 font-semiBold fs-16 '}>Comment</p>
                    <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                </div>
            </div>
            <div className={'simple-card py-4'}>
                <div className="grid grid-cols-1 gap-5 grid-cols-2 pb-3 px-4 md:ps-7 pe-5">
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Action</p>
                        <p className={'font-medium fs-15'}>Requested by</p>
                    </div>
                    <div>
                        <p className={'text-blue-800 font-semiBold fs-16'}>Date</p>
                        <p className={'font-medium fs-15 text-gray-700'}>28/02/2023-21:03</p>
                    </div>
                    <div className={'col-span-2'}>
                        <p className={'text-blue-800 font-semiBold fs-16 '}>Name- Department/ Section- Emp No.</p>
                        <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                        <p className={'font-medium fs-13  text-gray-700/30'}>Maintenance in charge</p>
                    </div>

                </div>
                <div className={'border-top-blue px-4 md:ps-7 pt-2'}>
                    <p className={'text-blue-800 font-semiBold fs-16 '}>Comment</p>
                    <p className={'font-medium fs-15  text-gray-700'}>Aymen Lamine- 2800</p>
                </div>
            </div>
        </div>
        <NewOrderModal
            isOpen={isOpenNewOrderModal}
            onOpen={openNewOrderModal}
            onClose={closeNewOrderModal}
            openAddItemModal={openAddItemModal}
        />
        <AddItemModal
            isOpen={isOpenAddItemModal}
            onOpen={openAddItemModal}
            onClose={closeAddItemModal}
            openAssignTechnicianModal={openAssignTechnicianModal}
        />
        <AssignTechnicianModal
            isOpen={isOpenAssignTechnicianModal}
            onOpen={openAssignTechnicianModal}
            onClose={closeAssignTechnicianModal}
        />
    </div>
  );
};


export default Supervisor;
