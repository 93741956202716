import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface CustomDatePickerProps {
    label?: string;
    placeholder?: string;
}
const CustomTimePicker: React.FC<CustomDatePickerProps> = ({ label, placeholder }) => {
    const [selectedTime, setSelectedTime] = useState(new Date());

    const handleChange = (date: React.SetStateAction<Date>) => {
        setSelectedTime(date);
    };

    return (
        <div className={'flex flex-col time-picker'}>
            {label && <label className={'font-medium fs-15 ps-3 dark:text-white'}>{label}</label>}
           <div className={'pt-1 w-full'}>
               <DatePicker
                   placeholderText={placeholder}
                   selected={selectedTime}
                   onChange={handleChange}
                   showTimeSelect
                   showTimeSelectOnly
                   timeIntervals={15}
                   timeCaption="Time"
                   dateFormat="h:mm aa"
               />
           </div>
        </div>

    );
};

export default CustomTimePicker;
