import { PurchaseTable} from "./components/purchaseTable";

import PurchaseModal from "./components/purchaseModal";
import {useDisclosure} from "@chakra-ui/hooks";

const PurchaseEngineer = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
    <div>

        <div className={'filter dashed-border pb-3 pt-1'}>
            <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 px-4 pb-3">
                <span>Title</span>
                <span>First aid medicines/ equipment </span>
            </div>
        </div>
        <div className={'flex justify-end pt-4'}>
            <button onClick={onOpen} className="btn bg-navy-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                <em className={'icon-plus'}></em>
                <span className={'ps-3'}>New department</span>
            </button>
        </div>
      <div className="mt-1 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          <PurchaseTable/>
      </div>
        <div className={'flex justify-center pt-4'}>

            <button className="btn border border-navy-500 border-navy-500 px-5 py-[12px] me-2 font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10">
                Cancel
            </button>
            <button className="btn linear bg-navy-500 py-[12px] ms-2 font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                Submit
            </button>

        </div>


        <PurchaseModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </div>
  );
};


export default PurchaseEngineer;
