import React, {useState} from "react";
import Stepper from "../../../../../../../../components/stepper";
import {useNavigate} from "react-router-dom";

const PlumbingMaintenanceChecklist = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState([]);

    const handleNext = () => {
        if (activeStep < 2) {
            setCompletedSteps([...Array(activeStep + 1).keys()]);
            setActiveStep(activeStep + 1);
        }
    };

    const handlePrevious = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
            // Remove the completed step when going back with "Previous" button
            setCompletedSteps((prevCompletedSteps) => prevCompletedSteps.filter((step) => step !== activeStep));
        }
    };
    const navigate = useNavigate();
    const backToCheckList = () => {
        navigate('/private/ppm-engineer/ppm-checkList');
    }
    return (
    <div className={'pt-5 px-5'}>
        <div className={'flex justify-center pb-8 flex-wrap'}>
            <div className={'dashed-border py-4 px-6 mx-4 mb-2'}>
                <span className={'font-medium fs-15 pe-8'}>Project code</span>
                <span className={'font-semiBold fs-16'}>C014 </span>
            </div>
            <div className={'dashed-border py-4 px-6 mx-4'}>
                <span className={'font-medium fs-15 pe-8'}>Project code</span>
                <span className={'font-semiBold fs-16'}>C014 </span>
            </div>
        </div>
      <div className="mt-1 grid h-full grid-cols-1 md:grid-cols-1">
          <p className={'font-bold fs-20 text-blue-800'}>Plumbing</p>
          <div>
              <Stepper
                  stepsNbr={2}
                  activeStep={activeStep}
                  completedSteps={completedSteps}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
              />
          </div>
      </div>
        <div className={'pt-10'}>
            { activeStep === 0 && (
                <div>
                    <div className={''}>
                        <p className={'font-medium fs-15 py-4'}>
                            00045 - Kitchen - Checkand clean faucet, drain trap. Check piping’s and horses for leaks
                        </p>
                        <div className="mt-1 grid h-full grid-cols-1 gap-7 md:grid-cols-2">
                            <div className={'col-span-1'}>
                                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Findings *</label>
                                <textarea placeholder="Note*"
                                          className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                          name="textarea" id="textarea">
                                    </textarea>
                            </div>
                            <div className={'col-span-1'}>
                                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Findings *</label>
                                <textarea placeholder="Note*"
                                          className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                          name="textarea" id="textarea">
                                    </textarea>
                            </div>
                        </div>
                    </div>
                    <div className={'pt-4'}>
                        <p className={'font-medium fs-15 py-4'}>
                            00265 - Water Supply - Check and clean pump room or check main water supply lines and valves
                        </p>
                        <div className="mt-1 grid h-full grid-cols-1 gap-7 md:grid-cols-2">
                            <div className={'col-span-1'}>
                                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Findings *</label>
                                <textarea placeholder="Note*"
                                          className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                          name="textarea" id="textarea">
                                    </textarea>
                            </div>
                            <div className={'col-span-1'}>
                                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Findings *</label>
                                <textarea placeholder="Note*"
                                          className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                          name="textarea" id="textarea">
                                    </textarea>
                            </div>
                        </div>
                    </div>
                    <div className={''}>
                        <p className={'font-medium fs-15 py-4'}>
                            0266 - Water Supply - Check water pump operation condition, pump for suction and discharge pressure
                        </p>
                        <div className="mt-1 grid h-full grid-cols-1 gap-7 md:grid-cols-2">
                            <div className={'col-span-1'}>
                                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Findings *</label>
                                <textarea placeholder="Note*"
                                          className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                          name="textarea" id="textarea">
                                    </textarea>
                            </div>
                            <div className={'col-span-1'}>
                                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Findings *</label>
                                <textarea placeholder="Note*"
                                          className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                          name="textarea" id="textarea">
                                    </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {activeStep === 1 && (
                <div>
                    <div className={''}>
                        <p className={'font-medium fs-15 py-4'}>
                            00263 - Toilets - Check floor drain, toilet bowl, seat and cover in all rooms, check piping for leaks.                        </p>
                        <div className="mt-1 grid h-full grid-cols-1 gap-7 md:grid-cols-2">
                            <div className={'col-span-1'}>
                                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Findings *</label>
                                <textarea placeholder="Note*"
                                          className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                          name="textarea" id="textarea">
                                    </textarea>
                            </div>
                            <div className={'col-span-1'}>
                                <label className={'font-medium fs-15 ps-3 dark:text-white'}>Findings *</label>
                                <textarea placeholder="Note*"
                                          className="mt-1 flex w-full items-center justify-center rounded-xl border border-blue-200 bg-white/0 pl-3 pt-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                          name="textarea" id="textarea">
                                    </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
        <div className="flex justify-center mt-6">

            <button onClick={handlePrevious} disabled={activeStep === 0} className="btn border border-navy-500 border-navy-500 px-5 py-[12px] me-2 font-medium text-navy-500 transition duration-200 hover:bg-navy-600/5 active:bg-navy-700/5 dark:border-navy-400 dark:bg-navy-400/10 dark:text-white dark:hover:bg-navy-300/10 dark:active:bg-navy-200/10">
                Previous
            </button>
            <button onClick={handleNext} disabled={activeStep === 1} className="btn linear bg-navy-500 py-[12px] ms-2 font-medium text-white transition duration-200 hover:bg-navy-600 active:bg-navy-700 dark:bg-navy-400 dark:text-white dark:hover:bg-navy-300 dark:active:bg-navy-200">
                Next
            </button>

        </div>
    </div>
  );
};


export default PlumbingMaintenanceChecklist;
