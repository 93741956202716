import {useNavigate} from "react-router-dom";
import React from "react";
import isMobile from "../../../../../../utils/mobileUtils";


const TABLE_HEAD = ["S/N",  "Project location", "Project code", "Type", "Period (From-> to)", "Created on","Created by",  "Frequency","State", "Responsible", "Actions" ];

const TABLE_ROWS = [
    {
        S_N: "1",
        Project_Location: "Karsd",
        Project_Code: "CCDR",
        Type: "Riyadh",
        Period: "Riyadh",
        CreatedOn: "Riyadh",
        Created_by: "Riyadh",
        Frequency: "Riyadh",
        State: "Riyadh",
        Responsible: "Riyadh",
    },
    {
        S_N: "1",
        Project_Location: "Karsd",
        Project_Code: "CCDR",
        Type: "Riyadh",
        Period: "Riyadh",
        CreatedOn: "Riyadh",
        Created_by: "Riyadh",
        Frequency: "Riyadh",
        State: "Riyadh",
        Responsible: "Riyadh",
    },
    {
        S_N: "1",
        Project_Location: "Karsd",
        Project_Code: "CCDR",
        Type: "Riyadh",
        Period: "Riyadh",
        CreatedOn: "Riyadh",
        Created_by: "Riyadh",
        Frequency: "Riyadh",
        State: "Riyadh",
        Responsible: "Riyadh",
    },
    {
        S_N: "1",
        Project_Location: "Karsd",
        Project_Code: "CCDR",
        Type: "Riyadh",
        Period: "Riyadh",
        CreatedOn: "Riyadh",
        Created_by: "Riyadh",
        Frequency: "Riyadh",
        State: "Riyadh",
        Responsible: "Riyadh",
    },

];

export function PPMScheduleListTable() {
    const navigate = useNavigate();
    const goToCheckList = () => {
        navigate('/private/ppm-engineer/ppm-checkList');
    };
    const isMobileView = isMobile();
    return (
        <div className="h-full w-full">
            
            { !isMobileView ?
                (
                    <div className="px-0 overflow-x-auto">
                        <table className="w-full min-w-max table-auto text-left custom-table">
                            <thead className={' border-radius-20'}>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head}
                                        className="bg-navy-200 border-y border-blue-gray-100 bg-blue-gray-50/50 p-2"
                                    >
                                <span className="font-normal text-white" >
                                    {head}
                                </span>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {TABLE_ROWS.map(
                                (
                                    {
                                        S_N,
                                        Project_Code,
                                        Project_Location,
                                        Type,
                                        Created_by,
                                        State,
                                        Responsible,
                                        Frequency,
                                        Period,
                                        CreatedOn,
                                    },
                                    index,
                                ) => {
                                    const isLast = index === TABLE_ROWS.length - 1;

                                    const classes = isLast
                                        ? 'p-2'
                                        : "p-2 border-b border-blue-gray-50";
                                    return (
                                        <tr key={S_N}>
                                            <td className={classes}>
                                                <div className="flex items-center gap-3">
                                                    <p className="font-medium text-gray-700 fs-16 ps-4">
                                                        {S_N}
                                                    </p>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <p className="font-medium text-gray-700 fs-16">
                                                    {Project_Location}
                                                </p>
                                            </td>
                                            <td className={classes}>
                                                <p className="font-medium text-gray-700 fs-16">
                                                    {Project_Code}
                                                </p>
                                            </td>
                                            <td className={classes}>
                                                <p className="font-medium text-gray-700 fs-16">
                                                    {Type}
                                                </p>
                                            </td>
                                            <td className={classes}>
                                                <p className="font-medium text-gray-700 fs-16">
                                                    {Period}
                                                </p>
                                            </td>
                                            <td className={classes}>
                                                <p className="font-medium text-gray-700 fs-16">
                                                    {CreatedOn}
                                                </p>
                                            </td>
                                            <td className={classes}>
                                                <p className="font-medium text-gray-700 fs-16">
                                                    {Created_by}
                                                </p>
                                            </td>
                                            <td className={classes}>
                                                <p className="font-medium text-gray-700 fs-16">
                                                    {Frequency}
                                                </p>
                                            </td>

                                            <td className={classes}>
                                                <p className="font-medium text-gray-700 fs-16">
                                                    {State}
                                                </p>
                                            </td>
                                            <td className={classes}>
                                                <p className="font-medium text-gray-700 fs-16">
                                                    {Responsible}
                                                </p>
                                            </td>
                                            <td className={classes}>
                                                <div className={'flex'}>
                                                    <button onClick={goToCheckList} className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                                        <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                            </tbody>
                        </table>
                    </div>

                )
                : (
                    <div className={'mobileView'}>
                        {TABLE_ROWS.map((body, rowIndex) => (
                            <div key={rowIndex} className="card-table my-3">
                                <div className="">

                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            S/N
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            41
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Project Location
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            1545
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Project Code
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            1545
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Type
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            Type
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Period (From -- to)
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            Period
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Created on
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            Created on
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Created by
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            Created by
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Frequency
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            Frequency
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            State
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            State
                                        </p>
                                    </div>
                                    <div className={'flex justify-between px-4 py-1'} >
                                        <p className="text-blue-800 font-semiBold fs-16">
                                            Responsible
                                        </p>
                                        <p className="font-medium text-gray-700 fs-16">
                                            Responsible
                                        </p>
                                    </div>

                                    <div className={'flex justify-center pt-4'}>
                                        <button onClick={goToCheckList} className="action-btn me-3 bg-blue-300 hover:bg-navy-200 active:bg-navy-400  flex items-center justify-center rounded-xl p-1 transition duration-200 hover:cursor-pointer dark:bg-navy-100 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
                                            <em className={'icon-eye text-white dark:text-navy-500 fs-16'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    );
}