import React from "react";

// private Imports
import MainDashboard from "views/private/dashboard";
import WorkRequest from "views/private/workOrderRequest/ControlCenter";
// Auth Imports
import SignIn from "views/auth/SignIn";
import SignUp from "views/auth/SignUp";

// Icon Imports
import {
  MdLock,
} from "react-icons/md";
import Project from "./views/private/projects";
import Employees from "./views/private/Employees/employee";
import PpmRequestor from "./views/private/ppm/ppm_requestor";
import PpmManager from "./views/private/ppm/ppm_manager";
import Settings from "./views/private/settings";
import EmployeeDetails from "./views/private/Employees/Employee-Details";
import RolePermission from "./views/private/Employees/Employee-Details/RolePermissions";
import WorkOrderRequest from "./views/private/workOrderRequest/supervisor";
import Technician from "./views/private/workOrderRequest/technician";
import Engineer from "./views/private/workOrderRequest/engineer";
import WorkRequestResult from "./views/private/workOrderRequest/workOrder-final-result";
import CustomerSatisfaction from "./views/private/customerSatisfaction";
import PPMScheduleCheckList from "./views/private/ppm/PPMShediuleEngineer/PPMScheduleCheckList";

import ElectricalMaintenanceChecklist
  from "./views/private/ppm/PPMShediuleEngineer/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/ElectricalMaintenanceChecklist";
import AirconditioningMaintenanceChecklist
  from "./views/private/ppm/PPMShediuleEngineer/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/AirconditioningMaintenanceChecklist";
import PlumbingMaintenanceChecklist
  from "./views/private/ppm/PPMShediuleEngineer/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/PlumbingMaintenanceChecklist";
import IrrigationSystemMaintenanceChecklist
  from "./views/private/ppm/PPMShediuleEngineer/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/IrrigationSystemMaintenanceChecklist";
import SwimmingPoolMaintenanceChecklist
  from "./views/private/ppm/PPMShediuleEngineer/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/SwimmingPoolMaintenanceChecklist";




import PPMScheduleCheckListTechnician from "./views/private/ppm/PPMShediuleTechnicien/PPMScheduleCheckList";
import ElectricalMaintenanceChecklistTechnician
  from "./views/private/ppm/PPMShediuleTechnicien/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/ElectricalMaintenanceChecklist";
import AirconditioningMaintenanceChecklistTechnician
  from "./views/private/ppm/PPMShediuleTechnicien/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/AirconditioningMaintenanceChecklist";
import PlumbingMaintenanceChecklistTechnician
  from "./views/private/ppm/PPMShediuleTechnicien/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/PlumbingMaintenanceChecklist";
import IrrigationSystemMaintenanceChecklistTechnician
  from "./views/private/ppm/PPMShediuleTechnicien/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/IrrigationSystemMaintenanceChecklist";
import SwimmingPoolMaintenanceChecklistTechnician
  from "./views/private/ppm/PPMShediuleTechnicien/PPMScheduleCheckList/components/PreventiveMaintenanceChecklist/SwimmingPoolMaintenanceChecklist";



import PpmApprove from "./views/private/ppm/ppm_manager/components/ppm-approver/Approver";

import PPMEngineer from "./views/private/ppm/PPMShediuleEngineer/PPMScheduleList";
import PurchaseEngineer from "./views/private/purchase/Engineer";
import PurchaseManager from "./views/private/purchase/manager";


const routes = [
  {
    name: "Dashboard",
    layout: "/private",
    path: "dashboard",
    icon: <em className={'icon-dashboard fs-22 ps-1'}/>,
    component: <MainDashboard/>,
    isParent: false,
  },
  {
    name: "Projects",
    layout: "/private",
    path: "projects",
    icon: <em className={'icon-folder fs-22 ps-1'}/>,
    component: <Project/>,
    isParent: false,
  },
  {
    name: "Work Order request",
    layout: "/private",
    path: "work-order",
    icon: <em className={'icon-list fs-27 ps-1'}/>,
    component: <WorkRequest />,
    isParent: false,
  },
  {
    name: "Work Order request",
    layout: "/private",
    path: "/work-order/request",
    icon: <em className={'icon-list fs-27 ps-1'}/>,
    component: <WorkOrderRequest />,
    show: false,
  },
  {

    name: "Maintenance Survey & Estimation Sheet",
    layout: "/private",
    path: "work-order-maintenance",
    icon: <em className={'icon-list fs-27 ps-1'}/>,
    component: <Technician />,
    show: false,
  },
  {
    name: "Report",
    layout: "/private",
    path: "work-order-report",
    icon: <em className={'icon-list fs-27 ps-1'}/>,
    component: <Engineer/>,
    show: false,
  },
  {
    name: "Customer satisfaction Survey- Results",
    layout: "/private",
    path: "customer-satisfaction",
    icon: <em className={'icon-list fs-27 ps-1'}/>,
    component: <WorkRequestResult/>,
    show: false,
  },
  {
    name: "Employees",
    layout: "/private",
    path: "employees",
    icon: <em className={'icon-users fs-22 ps-1'}/>,
    component: <Employees/>,
    isParent: false,
  },
  {
    name: "Employees - Employee details",
    layout: "/private",
    path: "employees/employee-details",
    icon: <em className={'icon-users fs-22 ps-1'}/>,
    component: <EmployeeDetails/>,
    show: false
  },
  {
    name: "Employees - Employee details - Roles and permissions",
    layout: "/private",
    path: "employees/employee-details/roles-permissions",
    icon: <em className={'icon-users fs-22 ps-1'}/>,
    component: <RolePermission/>,
    show: false
  },
  {
    name: "Customer satisfaction Survey",
    layout: "/private",
    path: "customer-satisfaction-client",
    icon: <em className={'icon-users fs-22 ps-1'}/>,
    show: true,
    component: <CustomerSatisfaction/>,
    isParent: false,
  },
  {
    name: "PPM Schedule for maintenance",
    layout: "/private",
    path: "ppm_requestor",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <PpmRequestor/>,
    show: false,
  },
  {
    name: "PPM Schedule for maintenance manager",
    layout: "/private",
    path: "ppm_manager",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <PpmManager/>,
    show: false,
  },
  {
    name: "PPM Schedule for maintenance",
    layout: "/private",
    path: "ppm_manager/ppm_approve",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <PpmApprove/>,
    show: false,
  },
  {
    name: "PPM Schedule for maintenance- List",
    layout: "/private",
    path: "ppm-engineer",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <PPMEngineer/>,
    show: false,
  },

  {
    name: "Preventive maintenance checklist",
    layout: "/private",
    path: "ppm-engineer/ppm-checkList",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <PPMScheduleCheckList/>,
    show: false,
  },
    // Engineer
  {
    name: "Preventive maintenance checklist (Electrical)",
    layout: "/private",
    path: "ppm-engineer/ppm-checkList/electrical-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <ElectricalMaintenanceChecklist/>,
    show: false,
  },
  {
    name: "Preventive maintenance checklist (Airconditionning)",
    layout: "/private",
    path: "ppm-engineer/ppm-checkList/airconditionning-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <AirconditioningMaintenanceChecklist/>,
    show: false,
  },
  {
    name: "Preventive maintenance checklist (Plumbing)",
    layout: "/private",
    path: "ppm-engineer/ppm-checkList/plumbing-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <PlumbingMaintenanceChecklist/>,
    show: false,
  },
  {
    name: "Preventive maintenance checklist (Irrigation System)",
    layout: "/private",
    path: "ppm-engineer/ppm-checkList/irrigation-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <IrrigationSystemMaintenanceChecklist/>,
    show: false,
  },
  {
    name: "Preventive maintenance checklist (Swimming pool)",
    layout: "/private",
    path: "ppm-engineer/ppm-checkList/swimming-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <SwimmingPoolMaintenanceChecklist/>,
    show: false,
  },

  // Technician

  {
    name: "Preventive maintenance checklist",
    layout: "/private",
    path: "ppm-technician/ppm-checkList",
    icon: '',
    component: <PPMScheduleCheckListTechnician/>,
    show: false,
  },
  {
    name: "Preventive maintenance checklist (Electrical)",
    layout: "/private",
    path: "ppm-technician/ppm-checkList/electrical-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <ElectricalMaintenanceChecklistTechnician/>,
    show: false,
  },
  {
    name: "Preventive maintenance checklist (Airconditionning)",
    layout: "/private",
    path: "ppm-technician/ppm-checkList/airconditionning-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <AirconditioningMaintenanceChecklistTechnician/>,
    show: false,
  },
  {
    name: "Preventive maintenance checklist (Plumbing)",
    layout: "/private",
    path: "ppm-technician/ppm-checkList/plumbing-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <PlumbingMaintenanceChecklistTechnician/>,
    show: false,
  },
  {
    name: "Preventive maintenance checklist (Irrigation System)",
    layout: "/private",
    path: "ppm-technician/ppm-checkList/irrigation-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <IrrigationSystemMaintenanceChecklistTechnician/>,
    show: false,
  },
  {
    name: "Preventive maintenance checklist (Swimming pool)",
    layout: "/private",
    path: "ppm-technician/ppm-checkList/swimming-maintenance",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    component: <SwimmingPoolMaintenanceChecklistTechnician/>,
    show: false,
  },

  {
    name: "PPM Schedule for maintenance",
    layout: "/private",
    icon: <em className={'icon-settign-calendar fs-27 ps-1'}/>,
    isParent: true,
    index: 0,
    children: [
      {
        name: "PPM Requestor",
        layout: "/private",
        path: "ppm_requestor",
        component: <PpmRequestor/>,
      },
      {
        name: "PPM Manager",
        layout: "/private",
        path: "ppm_manager",
        component: <PpmManager/>,
      },
      {
        name: "PPM Engineer",
        layout: "/private",
        path: "ppm-engineer",
        component: <PPMEngineer/>,
      },
      {
        name: "PPM Technician",
        layout: "/private",
        path: "ppm-technician/ppm-checkList",
        component: <PPMScheduleCheckListTechnician/>,
      }
    ],
  },

  {
    name: "Settings",
    layout: "/private",
    path: "settings",
    icon: <em className={'icon-gear fs-27 ps-1'}/>,
    component: <Settings/>,
    isParent: false,
  },

  {
    name: "purchase manager",
    layout: "/private",
    path: "purchase/manager",
    icon: <em className={'icon-gear fs-27 ps-1'}/>,
    component: <PurchaseManager/>,
    isParent: false,
  },

  {
    name: "purchase engineer",
    layout: "/private",
    path: "purchase/engineer",
    icon: <em className={'icon-gear fs-27 ps-1'}/>,
    component: <PurchaseEngineer/>,
    isParent: false,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,

  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignUp />,
  },

];
export default routes;
